import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Dialog } from "@/components/Dialog";
import { TemplateDownloadSection } from "./TemplateDownloadSection";
import { CurrentFilesDownloadSection } from "./CurrentFilesDownloadSection";
import { FileUploadSection } from "./FileUploadSection";
import { useUploadBudget } from "./useUploadBudget";
import { customToast } from "@/utils/customToast";
import * as Sentry from "@sentry/react";
import { PostBudgetRequest, BudgetValidationErrorResponse, ResponseError } from "@norma-bi/bi-api";

type BudgetDialogMode = "upload" | "edit";

type BudgetDialogProps = {
  show: boolean;
  onClose: () => void;
  mode: BudgetDialogMode;
  businessId: string;
  year: number;
};

const isBudgetValidationError = (data: unknown): data is BudgetValidationErrorResponse => {
  if (!(data && typeof data === "object" && "type" in data)) return false;

  switch (data.type) {
    case "EMPTY_CELL":
    case "INVALID_BRANCH_ID":
    case "INVALID_FILE":
    case "INVALID_FORMAT":
    case "INVALID_VALUE":
      return true;
    default:
      return false;
  }
};

const getErrorMessage = (error: BudgetValidationErrorResponse): string => {
  switch (error.type) {
    case "EMPTY_CELL":
      return `Empty cell found at row ${error.context.row}, column ${error.context.col}`;

    case "INVALID_BRANCH_ID":
      return `Invalid branch ID "${error.context.branch_id}" at row ${error.context.row}, column ${error.context.col}`;

    case "INVALID_FILE":
      return "The uploaded file format is invalid";

    case "INVALID_FORMAT":
      return `Invalid format at row ${error.context.row}, column ${error.context.col}: expected ${error.context.expected}, got ${error.context.got}`;

    case "INVALID_VALUE":
      return `Invalid value at row ${error.context.row}, column ${error.context.col}: expected ${error.context.expected}, got ${error.context.got}`;

    default:
      return "An unexpected error occurred";
  }
};

export function BudgetDialog({ show, onClose, mode, businessId, year }: BudgetDialogProps) {
  const [isUploadReady, setIsUploadReady] = React.useState(false);
  const [isValidating, setIsValidating] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const uploadBudget = useUploadBudget();
  const queryClient = useQueryClient();

  const handleConfirm = React.useCallback(() => {
    if (mode === "edit") {
      setIsUploadReady(false);
    }
    onClose();
  }, [onClose, mode]);

  const handleFileChange = async (file: File | null) => {
    setError(null);
    setIsUploadReady(false);

    if (file) {
      if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        setIsValidating(true);

        try {
          const budgetRequest: PostBudgetRequest = {
            business_id: businessId,
            year: year,
            body: file,
          };

          await uploadBudget.mutateAsync(budgetRequest);
          setIsUploadReady(true);
          queryClient.invalidateQueries({ queryKey: ["budget", businessId] });
          customToast("XLSX Upload Successful", "success", "bottom");
        } catch (error: unknown) {
          if (error instanceof ResponseError) {
            const responseData = await error.response.json();
            if (isBudgetValidationError(responseData)) {
              setError(getErrorMessage(responseData));
            } else {
              setError("An unexpected error occurred");
            }
          } else {
            setError("An unexpected error occurred");
            if (mode === "upload") {
              Sentry.captureException(error, {
                tags: {
                  component: mode === "upload" ? "UploadBudgetDialog" : "EditBudgetDialog",
                  action: "handleFileChange",
                  error_type: "unexpected_error",
                },
                extra: {
                  fileName: file.name,
                  fileSize: file.size,
                },
              });
            }
          }
        } finally {
          setIsValidating(false);
        }
      } else {
        setError("Please upload an XLSX file");
      }
    } else {
      setIsValidating(false);
      setIsUploadReady(false);
      setError(null);
    }
  };

  const dialogConfig = {
    upload: {
      title: "Upload XLSX file",
      DownloadSection: TemplateDownloadSection,
      confirmText: "Done",
      cancelText: "Cancel",
      showCancel: !isUploadReady,
      showConfirm: isUploadReady,
    },
    edit: {
      title: "Edit Budget",
      DownloadSection: CurrentFilesDownloadSection,
      confirmText: "Close",
      cancelText: "Cancel",
      showCancel: !isUploadReady,
      showConfirm: isUploadReady,
    },
  };

  const config = dialogConfig[mode];
  const DownloadSection = config.DownloadSection;

  return (
    <Dialog
      show={show}
      title={config.title}
      message={
        <div className="w-full">
          <DownloadSection />
          <FileUploadSection onFileSelect={handleFileChange} isValidating={isValidating} />
          {error && (
            <div className="mt-4 p-3 text-lg font-medium text-sematicRed text-center">{error}</div>
          )}
        </div>
      }
      onConfirm={handleConfirm}
      onCancel={mode === "edit" && isUploadReady ? undefined : onClose}
      confirmText={config.confirmText}
      cancelText={config.cancelText}
      showConfirm={config.showConfirm}
      showCancel={config.showCancel}
    />
  );
}
