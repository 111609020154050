/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestResetPasswordResponse
 */
export interface RequestResetPasswordResponse {
    /**
     * 
     * @type {string}
     * @memberof RequestResetPasswordResponse
     */
    result: RequestResetPasswordResponseResultEnum;
}


/**
 * @export
 */
export const RequestResetPasswordResponseResultEnum = {
    ok: 'ok',
    onboarded_jit: 'onboarded-jit',
    user_not_found_in_business: 'user-not-found-in-business',
    user_has_no_branches: 'user-has-no-branches',
    business_not_found: 'business-not-found'
} as const;
export type RequestResetPasswordResponseResultEnum = typeof RequestResetPasswordResponseResultEnum[keyof typeof RequestResetPasswordResponseResultEnum];


/**
 * Check if a given object implements the RequestResetPasswordResponse interface.
 */
export function instanceOfRequestResetPasswordResponse(value: object): value is RequestResetPasswordResponse {
    if (!('result' in value) || value['result'] === undefined) return false;
    return true;
}

export function RequestResetPasswordResponseFromJSON(json: any): RequestResetPasswordResponse {
    return RequestResetPasswordResponseFromJSONTyped(json, false);
}

export function RequestResetPasswordResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestResetPasswordResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'result': json['result'],
    };
}

export function RequestResetPasswordResponseToJSON(json: any): RequestResetPasswordResponse {
    return RequestResetPasswordResponseToJSONTyped(json, false);
}

export function RequestResetPasswordResponseToJSONTyped(value?: RequestResetPasswordResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'result': value['result'],
    };
}

