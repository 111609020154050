import React from "react";
import ReactDOM from "react-dom/client";

import { CookiesProvider } from "react-cookie";
import { QueryClientProvider } from "./QueryClientProvider";
import { Toaster } from "react-hot-toast";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import "./sentry";

import "./index.css";
import { Router } from "./router";
import { AuthProvider } from "./Auth";
import { APIProvider, OnboardingProgressEmitterProvider } from "./api";
import { ThoughtSpotAuthenticatorProvider } from "./api/thoughtSpotAuthenticator";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Toaster />
    <CookiesProvider>
      <QueryClientProvider>
        <ReactQueryDevtools initialIsOpen={false} />
        <APIProvider>
          <ThoughtSpotAuthenticatorProvider>
            <OnboardingProgressEmitterProvider>
              <AuthProvider>
                <Router />
              </AuthProvider>
            </OnboardingProgressEmitterProvider>
          </ThoughtSpotAuthenticatorProvider>
        </APIProvider>
      </QueryClientProvider>
    </CookiesProvider>
  </React.StrictMode>,
);
