/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface License
 */
export interface License {
    /**
     * 
     * @type {string}
     * @memberof License
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof License
     */
    start_date?: Date;
    /**
     * 
     * @type {Date}
     * @memberof License
     */
    end_date?: Date;
    /**
     * 
     * @type {string}
     * @memberof License
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof License
     */
    sku: string;
    /**
     * 
     * @type {number}
     * @memberof License
     */
    quantity: number;
}

/**
 * Check if a given object implements the License interface.
 */
export function instanceOfLicense(value: object): value is License {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('sku' in value) || value['sku'] === undefined) return false;
    if (!('quantity' in value) || value['quantity'] === undefined) return false;
    return true;
}

export function LicenseFromJSON(json: any): License {
    return LicenseFromJSONTyped(json, false);
}

export function LicenseFromJSONTyped(json: any, ignoreDiscriminator: boolean): License {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'start_date': json['start_date'] == null ? undefined : (new Date(json['start_date'])),
        'end_date': json['end_date'] == null ? undefined : (new Date(json['end_date'])),
        'status': json['status'],
        'sku': json['sku'],
        'quantity': json['quantity'],
    };
}

export function LicenseToJSON(json: any): License {
    return LicenseToJSONTyped(json, false);
}

export function LicenseToJSONTyped(value?: License | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'start_date': value['start_date'] == null ? undefined : ((value['start_date']).toISOString().substring(0,10)),
        'end_date': value['end_date'] == null ? undefined : ((value['end_date']).toISOString().substring(0,10)),
        'status': value['status'],
        'sku': value['sku'],
        'quantity': value['quantity'],
    };
}

