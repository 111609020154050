/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { ProductCategoryWithNetSales } from './ProductCategoryWithNetSales';
import {
    ProductCategoryWithNetSalesFromJSON,
    ProductCategoryWithNetSalesFromJSONTyped,
    ProductCategoryWithNetSalesToJSON,
    ProductCategoryWithNetSalesToJSONTyped,
} from './ProductCategoryWithNetSales';

/**
 * 
 * @export
 * @interface MenuMixBusinessProductCategoriesWithNetSalesListResponse
 */
export interface MenuMixBusinessProductCategoriesWithNetSalesListResponse {
    /**
     * 
     * @type {Array<ProductCategoryWithNetSales>}
     * @memberof MenuMixBusinessProductCategoriesWithNetSalesListResponse
     */
    data: Array<ProductCategoryWithNetSales>;
    /**
     * 
     * @type {Pagination}
     * @memberof MenuMixBusinessProductCategoriesWithNetSalesListResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the MenuMixBusinessProductCategoriesWithNetSalesListResponse interface.
 */
export function instanceOfMenuMixBusinessProductCategoriesWithNetSalesListResponse(value: object): value is MenuMixBusinessProductCategoriesWithNetSalesListResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function MenuMixBusinessProductCategoriesWithNetSalesListResponseFromJSON(json: any): MenuMixBusinessProductCategoriesWithNetSalesListResponse {
    return MenuMixBusinessProductCategoriesWithNetSalesListResponseFromJSONTyped(json, false);
}

export function MenuMixBusinessProductCategoriesWithNetSalesListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MenuMixBusinessProductCategoriesWithNetSalesListResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(ProductCategoryWithNetSalesFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

export function MenuMixBusinessProductCategoriesWithNetSalesListResponseToJSON(json: any): MenuMixBusinessProductCategoriesWithNetSalesListResponse {
    return MenuMixBusinessProductCategoriesWithNetSalesListResponseToJSONTyped(json, false);
}

export function MenuMixBusinessProductCategoriesWithNetSalesListResponseToJSONTyped(value?: MenuMixBusinessProductCategoriesWithNetSalesListResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(ProductCategoryWithNetSalesToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

