import { ChangeModeRequestModeEnum as AppMode } from "@norma-bi/bi-api";
import { ProLayout } from "@/ProLayout";
import { FEATURE_FLAGS } from "@/config/featureFlags";
import { LiteLayout } from "@/LiteLayout";
import { OldLiteLayout } from "@/OldLiteLayout";
import { useAppMode } from "@/hooks/useAppMode";
import { LoadingScreen } from "@/LoadingScreen";
import { Logout } from "@/Logout";

export function DynamicLayout() {
  const { data: mode, isLoading } = useAppMode();

  if (isLoading) {
    return <LoadingScreen />;
  }

  const LiteLayoutComponent = FEATURE_FLAGS.LITE_MODE_OLD ? OldLiteLayout : LiteLayout;

  return (
    <>
      {mode === AppMode.pro ? <ProLayout /> : <LiteLayoutComponent />}
      <Logout />
    </>
  );
}
