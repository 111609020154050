export type KeysOfType<T extends Record<string, unknown>, ValueType extends any> = {
  [K in keyof T]: T[K] extends ValueType ? K : never;
}[keyof T];

export type OmitFunctions<T> = {
  [K in keyof T as T[K] extends (...args: any[]) => any ? never : K]: T[K];
};

export type AllNonNullable<T extends Record<string, unknown>> = {
  [K in keyof T]-?: NonNullable<T[K]>;
};

export function AsRequired<T extends Record<string, unknown>>(input: T): AllNonNullable<T> {
  return input as AllNonNullable<T>;
}

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export function isDefined<T>(v: T | undefined | null): v is T {
  return v !== null && v !== undefined;
}
