import React from "react";
import { OnboardingProgress, SSEEmitterMessageListener } from "@norma-bi/bi-api";
import { useOnboardingProgressStore } from "@/stores/OnboardingProgressStore";
import { useOnboardingProgressEmitter } from "@/api";

function allHaveFinished(progress: OnboardingProgress) {
  return progress.length > 0 && progress.every((p) => p.current === p.total);
}

export function useInitializeOnboardingProgress() {
  const [isError, setIsError] = React.useState(false);

  const storeSetProgress = useOnboardingProgressStore((state) => state.setProgress);

  const { emitter, isAuthenticated } = useOnboardingProgressEmitter();

  React.useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    const listener = () => {
      setIsError(true);
    };
    emitter.addEventListener("error", listener);
    return () => emitter.removeEventListener("error", listener);
  }, [isAuthenticated, emitter]);

  React.useEffect(() => {
    if (!isAuthenticated || isError) {
      return;
    }
    const listener: SSEEmitterMessageListener<OnboardingProgress> = (data) => {
      storeSetProgress(data);
      if (allHaveFinished(data)) {
        emitter.removeEventListener("message", listener);
      }
    };
    emitter.addEventListener("message", listener);
    return () => {
      emitter.removeEventListener("message", listener);
    };
  }, [isAuthenticated, emitter, storeSetProgress, isError]);

  return { isError };
}
