/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { License } from './License';
import {
    LicenseFromJSON,
    LicenseFromJSONTyped,
    LicenseToJSON,
    LicenseToJSONTyped,
} from './License';

/**
 * 
 * @export
 * @interface LicenseCategory
 */
export interface LicenseCategory {
    /**
     * 
     * @type {string}
     * @memberof LicenseCategory
     */
    category: string;
    /**
     * 
     * @type {Array<License>}
     * @memberof LicenseCategory
     */
    licenses: Array<License>;
}

/**
 * Check if a given object implements the LicenseCategory interface.
 */
export function instanceOfLicenseCategory(value: object): value is LicenseCategory {
    if (!('category' in value) || value['category'] === undefined) return false;
    if (!('licenses' in value) || value['licenses'] === undefined) return false;
    return true;
}

export function LicenseCategoryFromJSON(json: any): LicenseCategory {
    return LicenseCategoryFromJSONTyped(json, false);
}

export function LicenseCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): LicenseCategory {
    if (json == null) {
        return json;
    }
    return {
        
        'category': json['category'],
        'licenses': ((json['licenses'] as Array<any>).map(LicenseFromJSON)),
    };
}

export function LicenseCategoryToJSON(json: any): LicenseCategory {
    return LicenseCategoryToJSONTyped(json, false);
}

export function LicenseCategoryToJSONTyped(value?: LicenseCategory | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'category': value['category'],
        'licenses': ((value['licenses'] as Array<any>).map(LicenseToJSON)),
    };
}

