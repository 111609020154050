import clsx from "clsx";

export type OldBusinessAvatarProps = {
  businessDisplayName: string;
  className?: string;
};

export function OldBusinessAvatar(props: OldBusinessAvatarProps) {
  return (
    <div
      className={clsx(
        "rounded-full w-10 h-10 cursor-pointer aspect-square",
        "flex items-center justify-center",
        "text-white font-medium text-sm",
        props.className,
      )}
    >
      {props.businessDisplayName.charAt(0).toUpperCase()}
    </div>
  );
}
