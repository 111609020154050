/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Branch } from './Branch';
import {
    BranchFromJSON,
    BranchFromJSONTyped,
    BranchToJSON,
    BranchToJSONTyped,
} from './Branch';

/**
 * 
 * @export
 * @interface BranchWithNetAmount
 */
export interface BranchWithNetAmount {
    /**
     * 
     * @type {Branch}
     * @memberof BranchWithNetAmount
     */
    branch: Branch;
    /**
     * 
     * @type {number}
     * @memberof BranchWithNetAmount
     */
    net_amount: number;
}

/**
 * Check if a given object implements the BranchWithNetAmount interface.
 */
export function instanceOfBranchWithNetAmount(value: object): value is BranchWithNetAmount {
    if (!('branch' in value) || value['branch'] === undefined) return false;
    if (!('net_amount' in value) || value['net_amount'] === undefined) return false;
    return true;
}

export function BranchWithNetAmountFromJSON(json: any): BranchWithNetAmount {
    return BranchWithNetAmountFromJSONTyped(json, false);
}

export function BranchWithNetAmountFromJSONTyped(json: any, ignoreDiscriminator: boolean): BranchWithNetAmount {
    if (json == null) {
        return json;
    }
    return {
        
        'branch': BranchFromJSON(json['branch']),
        'net_amount': json['net_amount'],
    };
}

export function BranchWithNetAmountToJSON(json: any): BranchWithNetAmount {
    return BranchWithNetAmountToJSONTyped(json, false);
}

export function BranchWithNetAmountToJSONTyped(value?: BranchWithNetAmount | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'branch': BranchToJSON(value['branch']),
        'net_amount': value['net_amount'],
    };
}

