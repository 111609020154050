/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    balance: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    total_amount: number;
    /**
     * 
     * @type {Date}
     * @memberof Invoice
     */
    issue_date: Date;
    /**
     * 
     * @type {Date}
     * @memberof Invoice
     */
    due_date?: Date;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    payment_status: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    payment_link?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    public_link?: string;
}

/**
 * Check if a given object implements the Invoice interface.
 */
export function instanceOfInvoice(value: object): value is Invoice {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('balance' in value) || value['balance'] === undefined) return false;
    if (!('total_amount' in value) || value['total_amount'] === undefined) return false;
    if (!('issue_date' in value) || value['issue_date'] === undefined) return false;
    if (!('payment_status' in value) || value['payment_status'] === undefined) return false;
    return true;
}

export function InvoiceFromJSON(json: any): Invoice {
    return InvoiceFromJSONTyped(json, false);
}

export function InvoiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Invoice {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'balance': json['balance'],
        'total_amount': json['total_amount'],
        'issue_date': (new Date(json['issue_date'])),
        'due_date': json['due_date'] == null ? undefined : (new Date(json['due_date'])),
        'type': json['type'] == null ? undefined : json['type'],
        'payment_status': json['payment_status'],
        'payment_link': json['payment_link'] == null ? undefined : json['payment_link'],
        'public_link': json['public_link'] == null ? undefined : json['public_link'],
    };
}

export function InvoiceToJSON(json: any): Invoice {
    return InvoiceToJSONTyped(json, false);
}

export function InvoiceToJSONTyped(value?: Invoice | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'balance': value['balance'],
        'total_amount': value['total_amount'],
        'issue_date': ((value['issue_date']).toISOString().substring(0,10)),
        'due_date': value['due_date'] == null ? undefined : ((value['due_date']).toISOString().substring(0,10)),
        'type': value['type'],
        'payment_status': value['payment_status'],
        'payment_link': value['payment_link'],
        'public_link': value['public_link'],
    };
}

