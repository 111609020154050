/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { ProductWithNetSalesAndQuantity } from './ProductWithNetSalesAndQuantity';
import {
    ProductWithNetSalesAndQuantityFromJSON,
    ProductWithNetSalesAndQuantityFromJSONTyped,
    ProductWithNetSalesAndQuantityToJSON,
    ProductWithNetSalesAndQuantityToJSONTyped,
} from './ProductWithNetSalesAndQuantity';

/**
 * 
 * @export
 * @interface MenuMixBusinessProductsWithNetSalesAndQuantityListResponse
 */
export interface MenuMixBusinessProductsWithNetSalesAndQuantityListResponse {
    /**
     * 
     * @type {Array<ProductWithNetSalesAndQuantity>}
     * @memberof MenuMixBusinessProductsWithNetSalesAndQuantityListResponse
     */
    data: Array<ProductWithNetSalesAndQuantity>;
    /**
     * 
     * @type {Pagination}
     * @memberof MenuMixBusinessProductsWithNetSalesAndQuantityListResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the MenuMixBusinessProductsWithNetSalesAndQuantityListResponse interface.
 */
export function instanceOfMenuMixBusinessProductsWithNetSalesAndQuantityListResponse(value: object): value is MenuMixBusinessProductsWithNetSalesAndQuantityListResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function MenuMixBusinessProductsWithNetSalesAndQuantityListResponseFromJSON(json: any): MenuMixBusinessProductsWithNetSalesAndQuantityListResponse {
    return MenuMixBusinessProductsWithNetSalesAndQuantityListResponseFromJSONTyped(json, false);
}

export function MenuMixBusinessProductsWithNetSalesAndQuantityListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MenuMixBusinessProductsWithNetSalesAndQuantityListResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(ProductWithNetSalesAndQuantityFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

export function MenuMixBusinessProductsWithNetSalesAndQuantityListResponseToJSON(json: any): MenuMixBusinessProductsWithNetSalesAndQuantityListResponse {
    return MenuMixBusinessProductsWithNetSalesAndQuantityListResponseToJSONTyped(json, false);
}

export function MenuMixBusinessProductsWithNetSalesAndQuantityListResponseToJSONTyped(value?: MenuMixBusinessProductsWithNetSalesAndQuantityListResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(ProductWithNetSalesAndQuantityToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

