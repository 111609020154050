/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Metric } from './Metric';
import {
    MetricFromJSON,
    MetricFromJSONTyped,
    MetricToJSON,
    MetricToJSONTyped,
} from './Metric';

/**
 * 
 * @export
 * @interface BranchPaymentMethodsNetAmountResponse
 */
export interface BranchPaymentMethodsNetAmountResponse {
    /**
     * 
     * @type {Array<Metric>}
     * @memberof BranchPaymentMethodsNetAmountResponse
     */
    data: Array<Metric>;
}

/**
 * Check if a given object implements the BranchPaymentMethodsNetAmountResponse interface.
 */
export function instanceOfBranchPaymentMethodsNetAmountResponse(value: object): value is BranchPaymentMethodsNetAmountResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function BranchPaymentMethodsNetAmountResponseFromJSON(json: any): BranchPaymentMethodsNetAmountResponse {
    return BranchPaymentMethodsNetAmountResponseFromJSONTyped(json, false);
}

export function BranchPaymentMethodsNetAmountResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BranchPaymentMethodsNetAmountResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(MetricFromJSON)),
    };
}

export function BranchPaymentMethodsNetAmountResponseToJSON(json: any): BranchPaymentMethodsNetAmountResponse {
    return BranchPaymentMethodsNetAmountResponseToJSONTyped(json, false);
}

export function BranchPaymentMethodsNetAmountResponseToJSONTyped(value?: BranchPaymentMethodsNetAmountResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(MetricToJSON)),
    };
}

