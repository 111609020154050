import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export type DataSourceStore = {
  searchDataSourceId: string;
  spotterDataSourceId: string;
  setSearchDataSourceId(dataSourceId: string): void;
  setSpotterDataSourceId(dataSourceId: string): void;
};

export const useDataSourceStore = create(
  persist<DataSourceStore>(
    (set) => ({
      searchDataSourceId: "",
      spotterDataSourceId: "",
      setSearchDataSourceId(dataSourceId: string) {
        set({ searchDataSourceId: dataSourceId });
      },
      setSpotterDataSourceId(dataSourceId: string) {
        set({ spotterDataSourceId: dataSourceId });
      },
    }),
    {
      name: "feature_data_sources",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
