import React from "react";
import { useCookies } from "react-cookie";

export function useAccessToken() {
  const [{ token }, setCookie, removeCookie] = useCookies(["token"]);

  return {
    accessToken: token,
    setAccessToken: React.useCallback(
      (token: string) => {
        const inTwoMonths = new Date();
        inTwoMonths.setMonth(inTwoMonths.getMonth() + 2);
        setCookie("token", token, { secure: true, expires: inTwoMonths });
      },
      [setCookie],
    ),
    removeAccessToken: React.useCallback(() => {
      // NOTE(yannis): Cookies are identified by many properties along with their names, such
      // as the domain property.
      // A cookie called "token" with a domain ".norma.app" is  different from a cookie
      // called "token" with a domain "my.foodics.norma.app", even though
      // the web-app can only set the domain property but cannot read it. (Until the CookieStore
      // API becomes widely available)
      // There are cases where the token cookie is set by the biserver with the domain
      // ".norma.app" to make it work across all norma domains.
      // As a result, in order to actually remove the token cookie we must remove it with
      // both possible domain property values.
      removeCookie("token", { domain: rootDomain(window.location.host) });
      removeCookie("token");
    }, [removeCookie]),
  };
}

// NOTE(yannis): I'm proud of copy-pasting this;
// you wake up one day and Norma runs in a .co.uk TLD and we try
// to debug why the root domain is not resolved correctly
function rootDomain(hostname: string) {
  if (hostname === "localhost") {
    return undefined;
  }
  let parts = hostname.split(".");
  if (parts.length <= 2) return hostname;

  parts = parts.slice(-3);
  if (["co", "com"].indexOf(parts[1]) > -1) return parts.join(".");

  return parts.slice(-2).join(".");
}
