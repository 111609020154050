import { InitOverrideFunction } from "@norma-bi/bi-api";

export function createThoughtSpotHeaders(thoughtspotToken: string): InitOverrideFunction {
  return async ({ init }) => ({
    ...init,
    headers: {
      ...init.headers,
      "X-THOUGHTSPOT-TOKEN": thoughtspotToken,
    },
  });
}
