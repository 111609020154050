/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Branch } from './Branch';
import {
    BranchFromJSON,
    BranchFromJSONTyped,
    BranchToJSON,
    BranchToJSONTyped,
} from './Branch';

/**
 * 
 * @export
 * @interface BranchWithDiscountAmount
 */
export interface BranchWithDiscountAmount {
    /**
     * 
     * @type {Branch}
     * @memberof BranchWithDiscountAmount
     */
    branch: Branch;
    /**
     * 
     * @type {number}
     * @memberof BranchWithDiscountAmount
     */
    discount_amount: number;
}

/**
 * Check if a given object implements the BranchWithDiscountAmount interface.
 */
export function instanceOfBranchWithDiscountAmount(value: object): value is BranchWithDiscountAmount {
    if (!('branch' in value) || value['branch'] === undefined) return false;
    if (!('discount_amount' in value) || value['discount_amount'] === undefined) return false;
    return true;
}

export function BranchWithDiscountAmountFromJSON(json: any): BranchWithDiscountAmount {
    return BranchWithDiscountAmountFromJSONTyped(json, false);
}

export function BranchWithDiscountAmountFromJSONTyped(json: any, ignoreDiscriminator: boolean): BranchWithDiscountAmount {
    if (json == null) {
        return json;
    }
    return {
        
        'branch': BranchFromJSON(json['branch']),
        'discount_amount': json['discount_amount'],
    };
}

export function BranchWithDiscountAmountToJSON(json: any): BranchWithDiscountAmount {
    return BranchWithDiscountAmountToJSONTyped(json, false);
}

export function BranchWithDiscountAmountToJSONTyped(value?: BranchWithDiscountAmount | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'branch': BranchToJSON(value['branch']),
        'discount_amount': value['discount_amount'],
    };
}

