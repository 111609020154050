/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BranchWithDiscountAmount } from './BranchWithDiscountAmount';
import {
    BranchWithDiscountAmountFromJSON,
    BranchWithDiscountAmountFromJSONTyped,
    BranchWithDiscountAmountToJSON,
    BranchWithDiscountAmountToJSONTyped,
} from './BranchWithDiscountAmount';

/**
 * 
 * @export
 * @interface BusinessReportBranchesWithDiscountAmountResponse
 */
export interface BusinessReportBranchesWithDiscountAmountResponse {
    /**
     * 
     * @type {Array<BranchWithDiscountAmount>}
     * @memberof BusinessReportBranchesWithDiscountAmountResponse
     */
    data: Array<BranchWithDiscountAmount>;
}

/**
 * Check if a given object implements the BusinessReportBranchesWithDiscountAmountResponse interface.
 */
export function instanceOfBusinessReportBranchesWithDiscountAmountResponse(value: object): value is BusinessReportBranchesWithDiscountAmountResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function BusinessReportBranchesWithDiscountAmountResponseFromJSON(json: any): BusinessReportBranchesWithDiscountAmountResponse {
    return BusinessReportBranchesWithDiscountAmountResponseFromJSONTyped(json, false);
}

export function BusinessReportBranchesWithDiscountAmountResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessReportBranchesWithDiscountAmountResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(BranchWithDiscountAmountFromJSON)),
    };
}

export function BusinessReportBranchesWithDiscountAmountResponseToJSON(json: any): BusinessReportBranchesWithDiscountAmountResponse {
    return BusinessReportBranchesWithDiscountAmountResponseToJSONTyped(json, false);
}

export function BusinessReportBranchesWithDiscountAmountResponseToJSONTyped(value?: BusinessReportBranchesWithDiscountAmountResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(BranchWithDiscountAmountToJSON)),
    };
}

