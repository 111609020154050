/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BusinessReportGeneralMetrics
 */
export interface BusinessReportGeneralMetrics {
    /**
     * 
     * @type {number}
     * @memberof BusinessReportGeneralMetrics
     */
    order_count: number;
    /**
     * 
     * @type {number}
     * @memberof BusinessReportGeneralMetrics
     */
    net_sales_per_order: number;
    /**
     * 
     * @type {number}
     * @memberof BusinessReportGeneralMetrics
     */
    void_amount: number;
    /**
     * 
     * @type {number}
     * @memberof BusinessReportGeneralMetrics
     */
    discount_amount: number;
}

/**
 * Check if a given object implements the BusinessReportGeneralMetrics interface.
 */
export function instanceOfBusinessReportGeneralMetrics(value: object): value is BusinessReportGeneralMetrics {
    if (!('order_count' in value) || value['order_count'] === undefined) return false;
    if (!('net_sales_per_order' in value) || value['net_sales_per_order'] === undefined) return false;
    if (!('void_amount' in value) || value['void_amount'] === undefined) return false;
    if (!('discount_amount' in value) || value['discount_amount'] === undefined) return false;
    return true;
}

export function BusinessReportGeneralMetricsFromJSON(json: any): BusinessReportGeneralMetrics {
    return BusinessReportGeneralMetricsFromJSONTyped(json, false);
}

export function BusinessReportGeneralMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessReportGeneralMetrics {
    if (json == null) {
        return json;
    }
    return {
        
        'order_count': json['order_count'],
        'net_sales_per_order': json['net_sales_per_order'],
        'void_amount': json['void_amount'],
        'discount_amount': json['discount_amount'],
    };
}

export function BusinessReportGeneralMetricsToJSON(json: any): BusinessReportGeneralMetrics {
    return BusinessReportGeneralMetricsToJSONTyped(json, false);
}

export function BusinessReportGeneralMetricsToJSONTyped(value?: BusinessReportGeneralMetrics | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'order_count': value['order_count'],
        'net_sales_per_order': value['net_sales_per_order'],
        'void_amount': value['void_amount'],
        'discount_amount': value['discount_amount'],
    };
}

