import toast, { Toast, ToastOptions } from "react-hot-toast";
import { colors } from "../theme/colors";

type ToastType = "success" | "error";
type ToastPreset = "default" | "bottom";

const bottomToastOptions: ToastOptions = {
  position: "bottom-center",
  duration: 3000,
  style: {
    background: colors.success,
    color: "#fff",
    padding: "16px",
    borderRadius: "4px",
    maxWidth: "none",
    whiteSpace: "nowrap",
    fontSize: "14px",
    fontWeight: "500",
  },
};

const createToastContent = (message: string, t: Toast) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <button
      onClick={() => toast.dismiss(t.id)}
      style={{
        background: "none",
        border: "none",
        color: "#fff",
        fontSize: "16px",
        marginRight: "10px",
        cursor: "pointer",
      }}
    >
      ✕
    </button>
    <span>{message}</span>
  </div>
);

export const customToast = (message: string, type: ToastType, preset: ToastPreset = "default") => {
  if (preset === "bottom") {
    const options = {
      ...bottomToastOptions,
      style: {
        ...bottomToastOptions.style,
        background: type === "error" ? colors.errorContent : colors.success,
      },
    };
    toast((t) => createToastContent(message, t), options);
  } else {
    toast[type](message);
  }
};
