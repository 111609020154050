/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccountCumulativeInfo
 */
export interface AccountCumulativeInfo {
    /**
     * 
     * @type {string}
     * @memberof AccountCumulativeInfo
     */
    cs_manager_name?: string;
    /**
     * 
     * @type {number}
     * @memberof AccountCumulativeInfo
     */
    active_licenses_count: number;
    /**
     * 
     * @type {number}
     * @memberof AccountCumulativeInfo
     */
    due_invoices_amount: number;
    /**
     * 
     * @type {number}
     * @memberof AccountCumulativeInfo
     */
    non_closed_support_tickets_count: number;
}

/**
 * Check if a given object implements the AccountCumulativeInfo interface.
 */
export function instanceOfAccountCumulativeInfo(value: object): value is AccountCumulativeInfo {
    if (!('active_licenses_count' in value) || value['active_licenses_count'] === undefined) return false;
    if (!('due_invoices_amount' in value) || value['due_invoices_amount'] === undefined) return false;
    if (!('non_closed_support_tickets_count' in value) || value['non_closed_support_tickets_count'] === undefined) return false;
    return true;
}

export function AccountCumulativeInfoFromJSON(json: any): AccountCumulativeInfo {
    return AccountCumulativeInfoFromJSONTyped(json, false);
}

export function AccountCumulativeInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountCumulativeInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'cs_manager_name': json['cs_manager_name'] == null ? undefined : json['cs_manager_name'],
        'active_licenses_count': json['active_licenses_count'],
        'due_invoices_amount': json['due_invoices_amount'],
        'non_closed_support_tickets_count': json['non_closed_support_tickets_count'],
    };
}

export function AccountCumulativeInfoToJSON(json: any): AccountCumulativeInfo {
    return AccountCumulativeInfoToJSONTyped(json, false);
}

export function AccountCumulativeInfoToJSONTyped(value?: AccountCumulativeInfo | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'cs_manager_name': value['cs_manager_name'],
        'active_licenses_count': value['active_licenses_count'],
        'due_invoices_amount': value['due_invoices_amount'],
        'non_closed_support_tickets_count': value['non_closed_support_tickets_count'],
    };
}

