/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BranchGroupWithNetSalesAndOrderCount } from './BranchGroupWithNetSalesAndOrderCount';
import {
    BranchGroupWithNetSalesAndOrderCountFromJSON,
    BranchGroupWithNetSalesAndOrderCountFromJSONTyped,
    BranchGroupWithNetSalesAndOrderCountToJSON,
    BranchGroupWithNetSalesAndOrderCountToJSONTyped,
} from './BranchGroupWithNetSalesAndOrderCount';

/**
 * 
 * @export
 * @interface BusinessReportBranchesWithNetSalesAndOrderCountPerMarketplaceAppResponse
 */
export interface BusinessReportBranchesWithNetSalesAndOrderCountPerMarketplaceAppResponse {
    /**
     * 
     * @type {Array<BranchGroupWithNetSalesAndOrderCount>}
     * @memberof BusinessReportBranchesWithNetSalesAndOrderCountPerMarketplaceAppResponse
     */
    data: Array<BranchGroupWithNetSalesAndOrderCount>;
}

/**
 * Check if a given object implements the BusinessReportBranchesWithNetSalesAndOrderCountPerMarketplaceAppResponse interface.
 */
export function instanceOfBusinessReportBranchesWithNetSalesAndOrderCountPerMarketplaceAppResponse(value: object): value is BusinessReportBranchesWithNetSalesAndOrderCountPerMarketplaceAppResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function BusinessReportBranchesWithNetSalesAndOrderCountPerMarketplaceAppResponseFromJSON(json: any): BusinessReportBranchesWithNetSalesAndOrderCountPerMarketplaceAppResponse {
    return BusinessReportBranchesWithNetSalesAndOrderCountPerMarketplaceAppResponseFromJSONTyped(json, false);
}

export function BusinessReportBranchesWithNetSalesAndOrderCountPerMarketplaceAppResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessReportBranchesWithNetSalesAndOrderCountPerMarketplaceAppResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(BranchGroupWithNetSalesAndOrderCountFromJSON)),
    };
}

export function BusinessReportBranchesWithNetSalesAndOrderCountPerMarketplaceAppResponseToJSON(json: any): BusinessReportBranchesWithNetSalesAndOrderCountPerMarketplaceAppResponse {
    return BusinessReportBranchesWithNetSalesAndOrderCountPerMarketplaceAppResponseToJSONTyped(json, false);
}

export function BusinessReportBranchesWithNetSalesAndOrderCountPerMarketplaceAppResponseToJSONTyped(value?: BusinessReportBranchesWithNetSalesAndOrderCountPerMarketplaceAppResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(BranchGroupWithNetSalesAndOrderCountToJSON)),
    };
}

