export function CompanyLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="115" height="15.54" viewBox="0 0 194.141 26.235">
      <defs>
        <clipPath id="clipPath">
          <rect id="Rectangle_321" width="194.141" height="26.235" fill="#FFFFFF"></rect>
        </clipPath>
      </defs>
      <g id="Group_1216" transform="translate(-2843.481 -6197.846)">
        <g id="Group_666" transform="translate(2843.481 6197.846)">
          <g id="Group_665" clipPath="url(#clipPath)">
            <path
              id="Path_192"
              d="M2986.214,6213.48h0a7.579,7.579,0,0,0-.983-2.057,7.262,7.262,0,0,0-1.425-1.542,8.642,8.642,0,0,0-3.434-1.658,8.806,8.806,0,0,0-1.675-.216h-10.4a1.964,1.964,0,0,1-1.5-.539,2.18,2.18,0,0,1-.524-1.572,2.241,2.241,0,0,1,.536-1.593,1.917,1.917,0,0,1,1.49-.555h16.011v-5.884H2968.3a8.708,8.708,0,0,0-2.56.421,7.627,7.627,0,0,0-4.561,3.876,9.2,9.2,0,0,0,0,7.479,7.573,7.573,0,0,0,1.986,2.494,7.77,7.77,0,0,0,2.574,1.373,8.772,8.772,0,0,0,2.56.421h10.351a2.338,2.338,0,0,1,.875.157,1.739,1.739,0,0,1,.617.418,1.918,1.918,0,0,1,.4.667,2.615,2.615,0,0,1,.146.9,2.17,2.17,0,0,1-.53,1.569,1.971,1.971,0,0,1-1.512.543h-18.055v5.885h18.055a8.281,8.281,0,0,0,1.691-.188,8.465,8.465,0,0,0,1.765-.586,8.631,8.631,0,0,0,1.676-1.013,6.646,6.646,0,0,0,1.446-1.545,7.866,7.866,0,0,0,.98-2.058,8.623,8.623,0,0,0,.372-2.607,8.763,8.763,0,0,0-.361-2.594"
              transform="translate(-2800.703 -6197.838)"
              fill="#FFFFFF"
            ></path>
            <path
              id="Path_193"
              d="M2888.662,6201h0a10.692,10.692,0,0,0-3.5-2.3,11.5,11.5,0,0,0-4.393-.829h-6.092a11.587,11.587,0,0,0-4.4.829,10.787,10.787,0,0,0-3.518,2.3,10.6,10.6,0,0,0-2.323,3.517,11.537,11.537,0,0,0-.841,4.426v4.048a11.557,11.557,0,0,0,.841,4.436,10.74,10.74,0,0,0,2.322,3.512,10.483,10.483,0,0,0,3.52,2.308,11.627,11.627,0,0,0,4.4.821h6.093a11.685,11.685,0,0,0,4.392-.821,10.447,10.447,0,0,0,3.508-2.308,10.557,10.557,0,0,0,2.314-3.515,11.639,11.639,0,0,0,.831-4.435v-4.047a11.694,11.694,0,0,0-.831-4.426,10.442,10.442,0,0,0-2.314-3.515m-13.987,2.748h6.094a5.84,5.84,0,0,1,2.129.376,4.542,4.542,0,0,1,2.654,2.656,5.927,5.927,0,0,1,.376,2.163v4.048a5.937,5.937,0,0,1-.376,2.164,4.573,4.573,0,0,1-1.046,1.614,4.787,4.787,0,0,1-1.627,1.043,5.856,5.856,0,0,1-2.143.378h-6.061a5.828,5.828,0,0,1-2.143-.378,4.746,4.746,0,0,1-1.628-1.043,4.639,4.639,0,0,1-1.048-1.614,5.941,5.941,0,0,1-.377-2.164v-4.048a5.957,5.957,0,0,1,.377-2.163,4.589,4.589,0,0,1,1.048-1.612,4.812,4.812,0,0,1,1.628-1.043,5.858,5.858,0,0,1,2.143-.376"
              transform="translate(-2836.122 -6197.838)"
              fill="#FFFFFF"
            ></path>
            <path
              id="Path_194"
              d="M2912.005,6201a10.777,10.777,0,0,0-3.5-2.3,11.5,11.5,0,0,0-4.392-.829h-6.095a11.609,11.609,0,0,0-4.4.829,10.757,10.757,0,0,0-3.518,2.3,10.555,10.555,0,0,0-2.322,3.517,11.509,11.509,0,0,0-.839,4.426v4.048a11.557,11.557,0,0,0,.839,4.436,10.7,10.7,0,0,0,2.322,3.512,10.478,10.478,0,0,0,3.521,2.308,11.629,11.629,0,0,0,4.4.821h6.095a11.686,11.686,0,0,0,4.392-.821,10.421,10.421,0,0,0,5.825-5.823,11.662,11.662,0,0,0,.831-4.435v-4.047a11.7,11.7,0,0,0-.831-4.426,10.6,10.6,0,0,0-2.323-3.515m-13.99,2.748h6.093a5.854,5.854,0,0,1,2.129.376,4.54,4.54,0,0,1,2.654,2.656,5.921,5.921,0,0,1,.377,2.163v4.048a5.931,5.931,0,0,1-.377,2.164,4.557,4.557,0,0,1-1.046,1.614,4.775,4.775,0,0,1-1.628,1.043,5.835,5.835,0,0,1-2.144.378h-6.059a5.821,5.821,0,0,1-2.144-.378,4.751,4.751,0,0,1-1.629-1.043,4.628,4.628,0,0,1-1.046-1.614,5.973,5.973,0,0,1-.377-2.164v-4.048a5.963,5.963,0,0,1,.377-2.163,4.6,4.6,0,0,1,1.046-1.612,4.816,4.816,0,0,1,1.629-1.043,5.88,5.88,0,0,1,2.144-.376"
              transform="translate(-2827.578 -6197.838)"
              fill="#FFFFFF"
            ></path>
            <path
              id="Path_195"
              d="M2933.512,6201h0a10.7,10.7,0,0,0-3.518-2.3,11.557,11.557,0,0,0-4.4-.83h-12.146a3.1,3.1,0,0,0-1.167.226,2.77,2.77,0,0,0-.948.643,3,3,0,0,0-.619.945,2.968,2.968,0,0,0-.227,1.149v20.286a2.973,2.973,0,0,0,.227,1.163,2.937,2.937,0,0,0,.624.945,3,3,0,0,0,.943.625,3.08,3.08,0,0,0,1.167.227H2925.6a11.6,11.6,0,0,0,4.4-.832,10.787,10.787,0,0,0,3.52-2.3,10.626,10.626,0,0,0,2.32-3.509,11.579,11.579,0,0,0,.84-4.434v-4.053a11.588,11.588,0,0,0-.84-4.426,10.639,10.639,0,0,0-2.329-3.519m-7.913,17.187h-9.229V6203.75h9.229a5.853,5.853,0,0,1,2.143.376,4.685,4.685,0,0,1,1.628,1.043,4.637,4.637,0,0,1,1.043,1.619,5.993,5.993,0,0,1,.376,2.158V6213a5.934,5.934,0,0,1-.376,2.162,4.58,4.58,0,0,1-1.046,1.614,4.792,4.792,0,0,1-1.628,1.045,5.989,5.989,0,0,1-2.14.367"
              transform="translate(-2818.961 -6197.838)"
              fill="#FFFFFF"
            ></path>
            <rect
              id="Rectangle_320"
              width="5.883"
              height="26.205"
              transform="translate(121.706 0.027)"
              fill="#FFFFFF"
            ></rect>
            <path
              id="Path_196"
              d="M2946.512,6198.7a10.458,10.458,0,0,0-5.844,5.82,11.557,11.557,0,0,0-.837,4.425v4.048a11.61,11.61,0,0,0,.837,4.425,10.453,10.453,0,0,0,5.844,5.822,11.546,11.546,0,0,0,4.4.829h12.844v-5.883h-12.844a5.857,5.857,0,0,1-2.144-.378,4.815,4.815,0,0,1-1.626-1.042,4.628,4.628,0,0,1-1.048-1.616,5.893,5.893,0,0,1-.377-2.162v-4.048a5.92,5.92,0,0,1,.377-2.165,4.6,4.6,0,0,1,1.048-1.612,4.8,4.8,0,0,1,1.626-1.043,5.877,5.877,0,0,1,2.144-.376h12.844v-5.885h-12.844a11.857,11.857,0,0,0-4.4.841"
              transform="translate(-2808.223 -6197.841)"
              fill="#FFFFFF"
            ></path>
            <path
              id="Path_197"
              d="M2848.967,6213.428h0a2.4,2.4,0,0,0-.765-.5,2.525,2.525,0,0,0-.961-.181h-1.334a2.547,2.547,0,0,0-.964.181,2.278,2.278,0,0,0-1.276,1.274,2.534,2.534,0,0,0-.186.968v1.037a2.474,2.474,0,0,0,.186.97,2.414,2.414,0,0,0,.508.771,2.268,2.268,0,0,0,.768.5,2.633,2.633,0,0,0,.96.179h1.334a2.611,2.611,0,0,0,.961-.179,2.35,2.35,0,0,0,.769-.5,2.425,2.425,0,0,0,.508-.771,2.536,2.536,0,0,0,.182-.97v-1.037a2.516,2.516,0,0,0-.182-.964,2.3,2.3,0,0,0-.508-.774"
              transform="translate(-2843.481 -6192.395)"
              fill="#FFFFFF"
            ></path>
            <path
              id="Path_198"
              d="M2982.692,6199.007h1.441a.739.739,0,0,1,.787.736.654.654,0,0,1-.426.671v.006c.288.073.37.358.39.624a1.472,1.472,0,0,0,.112.635h-.587a2.915,2.915,0,0,1-.093-.639c-.04-.281-.151-.406-.447-.406h-.588v1.045h-.587v-2.673Zm.588,1.213h.643c.262,0,.408-.112.408-.384s-.146-.369-.408-.369h-.643Z"
              transform="translate(-2792.54 -6197.421)"
              fill="#FFFFFF"
            ></path>
            <path
              id="Path_199"
              d="M2984.246,6203.751a2.944,2.944,0,1,1,2.942-2.944,2.949,2.949,0,0,1-2.942,2.944m0-5.26a2.316,2.316,0,1,0,2.317,2.316,2.321,2.321,0,0,0-2.317-2.316"
              transform="translate(-2793.047 -6197.838)"
              fill="#FFFFFF"
            ></path>
            <path
              id="Path_200"
              d="M2849.47,6205.475a1.681,1.681,0,0,1,1.684-1.682h16.179v-5.949h-16.039a7.769,7.769,0,0,0-7.766,7.771v2.58a7.769,7.769,0,0,0,7.766,7.773h8.454v-5.947h-8.594a1.681,1.681,0,0,1-1.684-1.684v-2.862Z"
              transform="translate(-2843.465 -6197.844)"
              fill="#FFFFFF"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
