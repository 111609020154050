import React from "react";
import { SettingsBudgetTable } from "./SettingsBudgetTable";
import { PulseLoader } from "react-spinners";
import { useGetBudget } from "@/hooks/useGetBudget";
import * as Sentry from "@sentry/react";
import { EmptyBox } from "./EmptyBox";
import { BudgetHeader } from "./BudgetHeader";
import { BudgetDialog } from "./BudgetDialog";
import { BudgetProvider } from "./BudgetProvider";
import { useBudgetContext } from "./useBudgetContext";

export function SettingsBudget() {
  return (
    <BudgetProvider>
      <SettingsBudgetContent />
    </BudgetProvider>
  );
}

function SettingsBudgetContent() {
  const { selectedYear, selectedResolution, budgetData, setBudgetData, selectedBusinessId } =
    useBudgetContext();
  const [showUploadDialog, setShowUploadDialog] = React.useState(false);

  const budget = useGetBudget(selectedBusinessId || undefined, selectedResolution, selectedYear);

  React.useEffect(() => {
    if (budget.data) {
      setBudgetData(budget.data);
    } else {
      setBudgetData(null);
    }
  }, [budget.data, setBudgetData]);

  let content;

  if (budget.isLoading) {
    content = (
      <div className="flex-1 min-h-0">
        <div className="h-full flex justify-center items-center pt-20">
          <PulseLoader color="lightgray" loading={true} size={15} speedMultiplier={0.6} />
        </div>
      </div>
    );
  } else if (budget.isError) {
    Sentry.captureException(budget.error, {
      extra: {
        message: "budget.isSuccess is false",
        extra: { error: budget.error },
      },
    });
    content = null;
  } else if (!budgetData || budgetData.venue_budgets.length === 0) {
    content = (
      <div className="flex-1 min-h-0">
        <div className="h-full">
          <div className="flex justify-center items-center flex-col gap-y-10 p-20">
            <EmptyBox />
            <div className="flex flex-col justify-center gap-y-2 text-center">
              <div className="text-dark text-2xl font-semibold">
                Seems like there is no XLSX uploaded yet
              </div>
              <div className="text-feintGray text-sm">Provide us proper XLSX to view the data</div>
            </div>
            <button
              className="px-6 py-2 bg-secondaryColor hover:bg-primaryColor text-white rounded-2xl text-sm flex justify-around items-center"
              onClick={() => setShowUploadDialog(true)}
            >
              <span>Upload XLSX</span>
            </button>
          </div>
          <BudgetDialog
            show={showUploadDialog}
            mode="upload"
            onClose={() => setShowUploadDialog(false)}
            businessId={selectedBusinessId!}
            year={selectedYear}
          />
        </div>
      </div>
    );
  } else {
    content = (
      <div className="flex-1 min-h-0">
        <div className="h-full overflow-x-auto overflow-y-auto">
          <div className="min-w-full inline-block align-middle">
            <SettingsBudgetTable
              businessId={selectedBusinessId!}
              year={selectedYear}
              resolution={selectedResolution}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full">
      <BudgetHeader />
      {content}
    </div>
  );
}
