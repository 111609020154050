/* eslint-disable react-refresh/only-export-components */
import React from "react";
import { DefaultApiInterface } from "@norma-bi/bi-api";
import { apiFactory } from "./context";
import { useAccessToken } from "../Auth/useAccessToken";

export class ThoughtSpotAuthenticator {
  constructor(private readonly api: DefaultApiInterface) {}

  async getThoughtSpotToken(): Promise<string> {
    return (await this.api.getThoughtspotToken()).token;
  }
}

const thoughtSpotContext = React.createContext<ThoughtSpotAuthenticator | null>(null);

export function ThoughtSpotAuthenticatorProvider(props: React.PropsWithChildren) {
  const { accessToken } = useAccessToken();

  const api = React.useMemo(() => {
    if (!accessToken) {
      return;
    }
    return apiFactory({ accessToken });
  }, [accessToken]);

  const authenticator = React.useMemo(() => {
    if (!api) {
      return null;
    }
    return new ThoughtSpotAuthenticator(api);
  }, [api]);

  return <thoughtSpotContext.Provider {...props} value={authenticator} />;
}

export function useThoughtSpotAuthenticator() {
  return React.useContext(thoughtSpotContext);
}
