/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BranchWithNetAmount } from './BranchWithNetAmount';
import {
    BranchWithNetAmountFromJSON,
    BranchWithNetAmountFromJSONTyped,
    BranchWithNetAmountToJSON,
    BranchWithNetAmountToJSONTyped,
} from './BranchWithNetAmount';
import type { IDName } from './IDName';
import {
    IDNameFromJSON,
    IDNameFromJSONTyped,
    IDNameToJSON,
    IDNameToJSONTyped,
} from './IDName';

/**
 * 
 * @export
 * @interface BranchGroupWithNetAmount
 */
export interface BranchGroupWithNetAmount {
    /**
     * 
     * @type {IDName}
     * @memberof BranchGroupWithNetAmount
     */
    group: IDName;
    /**
     * 
     * @type {Array<BranchWithNetAmount>}
     * @memberof BranchGroupWithNetAmount
     */
    branches: Array<BranchWithNetAmount>;
}

/**
 * Check if a given object implements the BranchGroupWithNetAmount interface.
 */
export function instanceOfBranchGroupWithNetAmount(value: object): value is BranchGroupWithNetAmount {
    if (!('group' in value) || value['group'] === undefined) return false;
    if (!('branches' in value) || value['branches'] === undefined) return false;
    return true;
}

export function BranchGroupWithNetAmountFromJSON(json: any): BranchGroupWithNetAmount {
    return BranchGroupWithNetAmountFromJSONTyped(json, false);
}

export function BranchGroupWithNetAmountFromJSONTyped(json: any, ignoreDiscriminator: boolean): BranchGroupWithNetAmount {
    if (json == null) {
        return json;
    }
    return {
        
        'group': IDNameFromJSON(json['group']),
        'branches': ((json['branches'] as Array<any>).map(BranchWithNetAmountFromJSON)),
    };
}

export function BranchGroupWithNetAmountToJSON(json: any): BranchGroupWithNetAmount {
    return BranchGroupWithNetAmountToJSONTyped(json, false);
}

export function BranchGroupWithNetAmountToJSONTyped(value?: BranchGroupWithNetAmount | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'group': IDNameToJSON(value['group']),
        'branches': ((value['branches'] as Array<any>).map(BranchWithNetAmountToJSON)),
    };
}

