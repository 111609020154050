/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Branch } from './Branch';
import {
    BranchFromJSON,
    BranchFromJSONTyped,
    BranchToJSON,
    BranchToJSONTyped,
} from './Branch';

/**
 * 
 * @export
 * @interface BranchWithNetSalesPerOrderAndOrderCount
 */
export interface BranchWithNetSalesPerOrderAndOrderCount {
    /**
     * 
     * @type {Branch}
     * @memberof BranchWithNetSalesPerOrderAndOrderCount
     */
    branch: Branch;
    /**
     * 
     * @type {number}
     * @memberof BranchWithNetSalesPerOrderAndOrderCount
     */
    net_sales_per_order: number;
    /**
     * 
     * @type {number}
     * @memberof BranchWithNetSalesPerOrderAndOrderCount
     */
    order_count: number;
}

/**
 * Check if a given object implements the BranchWithNetSalesPerOrderAndOrderCount interface.
 */
export function instanceOfBranchWithNetSalesPerOrderAndOrderCount(value: object): value is BranchWithNetSalesPerOrderAndOrderCount {
    if (!('branch' in value) || value['branch'] === undefined) return false;
    if (!('net_sales_per_order' in value) || value['net_sales_per_order'] === undefined) return false;
    if (!('order_count' in value) || value['order_count'] === undefined) return false;
    return true;
}

export function BranchWithNetSalesPerOrderAndOrderCountFromJSON(json: any): BranchWithNetSalesPerOrderAndOrderCount {
    return BranchWithNetSalesPerOrderAndOrderCountFromJSONTyped(json, false);
}

export function BranchWithNetSalesPerOrderAndOrderCountFromJSONTyped(json: any, ignoreDiscriminator: boolean): BranchWithNetSalesPerOrderAndOrderCount {
    if (json == null) {
        return json;
    }
    return {
        
        'branch': BranchFromJSON(json['branch']),
        'net_sales_per_order': json['net_sales_per_order'],
        'order_count': json['order_count'],
    };
}

export function BranchWithNetSalesPerOrderAndOrderCountToJSON(json: any): BranchWithNetSalesPerOrderAndOrderCount {
    return BranchWithNetSalesPerOrderAndOrderCountToJSONTyped(json, false);
}

export function BranchWithNetSalesPerOrderAndOrderCountToJSONTyped(value?: BranchWithNetSalesPerOrderAndOrderCount | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'branch': BranchToJSON(value['branch']),
        'net_sales_per_order': value['net_sales_per_order'],
        'order_count': value['order_count'],
    };
}

