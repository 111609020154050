/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvoicesCumulativeInfo
 */
export interface InvoicesCumulativeInfo {
    /**
     * 
     * @type {number}
     * @memberof InvoicesCumulativeInfo
     */
    unpaid_invoices_count: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicesCumulativeInfo
     */
    amount_due: number;
}

/**
 * Check if a given object implements the InvoicesCumulativeInfo interface.
 */
export function instanceOfInvoicesCumulativeInfo(value: object): value is InvoicesCumulativeInfo {
    if (!('unpaid_invoices_count' in value) || value['unpaid_invoices_count'] === undefined) return false;
    if (!('amount_due' in value) || value['amount_due'] === undefined) return false;
    return true;
}

export function InvoicesCumulativeInfoFromJSON(json: any): InvoicesCumulativeInfo {
    return InvoicesCumulativeInfoFromJSONTyped(json, false);
}

export function InvoicesCumulativeInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoicesCumulativeInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'unpaid_invoices_count': json['unpaid_invoices_count'],
        'amount_due': json['amount_due'],
    };
}

export function InvoicesCumulativeInfoToJSON(json: any): InvoicesCumulativeInfo {
    return InvoicesCumulativeInfoToJSONTyped(json, false);
}

export function InvoicesCumulativeInfoToJSONTyped(value?: InvoicesCumulativeInfo | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'unpaid_invoices_count': value['unpaid_invoices_count'],
        'amount_due': value['amount_due'],
    };
}

