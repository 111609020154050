/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductCategory } from './ProductCategory';
import {
    ProductCategoryFromJSON,
    ProductCategoryFromJSONTyped,
    ProductCategoryToJSON,
    ProductCategoryToJSONTyped,
} from './ProductCategory';

/**
 * 
 * @export
 * @interface ProductCategoryWithNetSales
 */
export interface ProductCategoryWithNetSales {
    /**
     * 
     * @type {ProductCategory}
     * @memberof ProductCategoryWithNetSales
     */
    product_category: ProductCategory;
    /**
     * 
     * @type {number}
     * @memberof ProductCategoryWithNetSales
     */
    net_sales: number;
}

/**
 * Check if a given object implements the ProductCategoryWithNetSales interface.
 */
export function instanceOfProductCategoryWithNetSales(value: object): value is ProductCategoryWithNetSales {
    if (!('product_category' in value) || value['product_category'] === undefined) return false;
    if (!('net_sales' in value) || value['net_sales'] === undefined) return false;
    return true;
}

export function ProductCategoryWithNetSalesFromJSON(json: any): ProductCategoryWithNetSales {
    return ProductCategoryWithNetSalesFromJSONTyped(json, false);
}

export function ProductCategoryWithNetSalesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductCategoryWithNetSales {
    if (json == null) {
        return json;
    }
    return {
        
        'product_category': ProductCategoryFromJSON(json['product_category']),
        'net_sales': json['net_sales'],
    };
}

export function ProductCategoryWithNetSalesToJSON(json: any): ProductCategoryWithNetSales {
    return ProductCategoryWithNetSalesToJSONTyped(json, false);
}

export function ProductCategoryWithNetSalesToJSONTyped(value?: ProductCategoryWithNetSales | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'product_category': ProductCategoryToJSON(value['product_category']),
        'net_sales': value['net_sales'],
    };
}

