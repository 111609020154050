/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrderTag } from './OrderTag';
import {
    OrderTagFromJSON,
    OrderTagFromJSONTyped,
    OrderTagToJSON,
    OrderTagToJSONTyped,
} from './OrderTag';

/**
 * 
 * @export
 * @interface OrderTagWithNetSalesAndQuantity
 */
export interface OrderTagWithNetSalesAndQuantity {
    /**
     * 
     * @type {OrderTag}
     * @memberof OrderTagWithNetSalesAndQuantity
     */
    order_tag: OrderTag;
    /**
     * 
     * @type {number}
     * @memberof OrderTagWithNetSalesAndQuantity
     */
    net_sales: number;
    /**
     * 
     * @type {number}
     * @memberof OrderTagWithNetSalesAndQuantity
     */
    quantity: number;
}

/**
 * Check if a given object implements the OrderTagWithNetSalesAndQuantity interface.
 */
export function instanceOfOrderTagWithNetSalesAndQuantity(value: object): value is OrderTagWithNetSalesAndQuantity {
    if (!('order_tag' in value) || value['order_tag'] === undefined) return false;
    if (!('net_sales' in value) || value['net_sales'] === undefined) return false;
    if (!('quantity' in value) || value['quantity'] === undefined) return false;
    return true;
}

export function OrderTagWithNetSalesAndQuantityFromJSON(json: any): OrderTagWithNetSalesAndQuantity {
    return OrderTagWithNetSalesAndQuantityFromJSONTyped(json, false);
}

export function OrderTagWithNetSalesAndQuantityFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTagWithNetSalesAndQuantity {
    if (json == null) {
        return json;
    }
    return {
        
        'order_tag': OrderTagFromJSON(json['order_tag']),
        'net_sales': json['net_sales'],
        'quantity': json['quantity'],
    };
}

export function OrderTagWithNetSalesAndQuantityToJSON(json: any): OrderTagWithNetSalesAndQuantity {
    return OrderTagWithNetSalesAndQuantityToJSONTyped(json, false);
}

export function OrderTagWithNetSalesAndQuantityToJSONTyped(value?: OrderTagWithNetSalesAndQuantity | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'order_tag': OrderTagToJSON(value['order_tag']),
        'net_sales': value['net_sales'],
        'quantity': value['quantity'],
    };
}

