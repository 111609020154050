/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Branch } from './Branch';
import {
    BranchFromJSON,
    BranchFromJSONTyped,
    BranchToJSON,
    BranchToJSONTyped,
} from './Branch';

/**
 * 
 * @export
 * @interface BranchWithQuantity
 */
export interface BranchWithQuantity {
    /**
     * 
     * @type {Branch}
     * @memberof BranchWithQuantity
     */
    branch: Branch;
    /**
     * 
     * @type {number}
     * @memberof BranchWithQuantity
     */
    quantity: number;
}

/**
 * Check if a given object implements the BranchWithQuantity interface.
 */
export function instanceOfBranchWithQuantity(value: object): value is BranchWithQuantity {
    if (!('branch' in value) || value['branch'] === undefined) return false;
    if (!('quantity' in value) || value['quantity'] === undefined) return false;
    return true;
}

export function BranchWithQuantityFromJSON(json: any): BranchWithQuantity {
    return BranchWithQuantityFromJSONTyped(json, false);
}

export function BranchWithQuantityFromJSONTyped(json: any, ignoreDiscriminator: boolean): BranchWithQuantity {
    if (json == null) {
        return json;
    }
    return {
        
        'branch': BranchFromJSON(json['branch']),
        'quantity': json['quantity'],
    };
}

export function BranchWithQuantityToJSON(json: any): BranchWithQuantity {
    return BranchWithQuantityToJSONTyped(json, false);
}

export function BranchWithQuantityToJSONTyped(value?: BranchWithQuantity | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'branch': BranchToJSON(value['branch']),
        'quantity': value['quantity'],
    };
}

