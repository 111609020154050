import { ConversationEmbed, Action } from "@thoughtspot/visual-embed-sdk/react";
import { useThoughtSpotEmbedCommonProps } from "@/thoughtspot";
import { useDataSourceStore } from "@/stores/DataSourceStore";
import { COMMON_HIDDEN_ACTIONS } from "@/thoughtspot/constants";

const hiddenActions: Action[] = [
  ...COMMON_HIDDEN_ACTIONS,
  Action.Edit,
  Action.Save,
  Action.CopyAndEdit,
  Action.Pin,
  Action.AddToFavorites,
];

export function Spotter() {
  const selectedDataSourceId = useDataSourceStore((state) => state.spotterDataSourceId);
  const commonProps = useThoughtSpotEmbedCommonProps();

  if (!selectedDataSourceId) {
    return null;
  }

  return (
    <ConversationEmbed
      {...commonProps}
      className="w-full h-full"
      worksheetId={selectedDataSourceId}
      hideSourceSelection={true}
      hiddenActions={hiddenActions}
      frameParams={{
        width: "100%",
        height: "100%",
      }}
      searchOptions={{
        searchQuery: "",
        ...commonProps.searchOptions,
      }}
    />
  );
}
