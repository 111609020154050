import { useQuery } from "@tanstack/react-query";
import { useAPI } from "@/api";
import { useThoughtSpotToken } from "@/thoughtspot";
import { createThoughtSpotHeaders } from "@/api/thoughtspot";

export const LiveboardsQueryKey = "liveboards";

export function useLiveboards() {
  const api = useAPI();
  const thoughtspotToken = useThoughtSpotToken((state) => state.token);

  return useQuery({
    queryKey: [LiveboardsQueryKey],
    queryFn: async () => {
      const r = await api.getThoughtspotLiveboards(createThoughtSpotHeaders(thoughtspotToken!));
      return r.data;
    },
    enabled: api.isAuthenticated && !!thoughtspotToken,
  });
}
