import { ColumnDef } from "@tanstack/react-table";
import { Business } from "@norma-bi/bi-api";
import { BaseSettingsTable } from "../components/BaseSettingsTable";
import { BaseSettingsCell } from "../components/BaseSettingsCell";
import { BusinessContextMenu } from "./BusinessContextMenu";
import React from "react";
import { SvgIcon } from "@/svg";
import { Dialog } from "@/components/Dialog";
import { useAPI, initiateFoodicsBusinessOnboardingInExistingOrganization } from "@/api";
import clsx from "clsx";

export type SettingsBusinessesTableProps = {
  businesses: Business[];
  tableClassName?: string;
};

const EditColumnComponent = ({ row }: { row: Business }) => {
  const [showEditOptions, setShowEditOptions] = React.useState(false);

  return (
    <div className="relative">
      <div
        className={clsx("cursor-pointer", {
          visible: showEditOptions,
          "invisible group-hover:visible": !showEditOptions,
          "text-dark": showEditOptions,
          "text-light group-hover:text-lightgray hover:text-dark!": !showEditOptions,
        })}
        onClick={() => setShowEditOptions(!showEditOptions)}
      >
        <div className="inline-block">
          <SvgIcon icon="Edit" />
        </div>
      </div>
      <BusinessContextMenu show={showEditOptions} setShow={setShowEditOptions} business={row} />
    </div>
  );
};

export function SettingsBusinessesTable({
  businesses,
  tableClassName = "settings-table",
}: SettingsBusinessesTableProps) {
  const [showAddBusinessDialog, setShowAddBusinessDialog] = React.useState(false);
  const columns: ColumnDef<Business, keyof Business>[] = [
    {
      accessorKey: "display_name",
      header: "Name",
      cell: ({ getValue }) => BaseSettingsCell.avatar(getValue()),
    },
    {
      accessorKey: "name",
      header: "Business Name",
    },
    {
      accessorKey: "venues_count",
      header: "Venues",
    },
    {
      accessorKey: "users_count",
      header: "Users",
    },
  ];

  const api = useAPI();

  const TopSection = (
    <div>
      <button
        className="px-4 py-2 bg-backgroundGray hover:bg-light rounded-2xl w-[175px] h-[40px] flex justify-around items-center"
        onClick={() => setShowAddBusinessDialog(true)}
      >
        <SvgIcon icon="Plus" />
        <span>Add Business</span>
      </button>
    </div>
  );

  return (
    <>
      <BaseSettingsTable
        data={businesses}
        columns={columns}
        tableClassName={tableClassName}
        TopSection={TopSection}
        renderEditColumn={(business) => <EditColumnComponent row={business} />}
      />
      <Dialog
        show={showAddBusinessDialog}
        title="Add Business"
        message={
          <div style={{ textAlign: "center" }}>
            Make sure to <strong className="text-dark">Logout from the Console</strong> before you
            onboard a new Business.
            <br />
            Then enter the <strong className="text-dark">Owner credentials</strong> of the new
            Business.
          </div>
        }
        onConfirm={() => initiateFoodicsBusinessOnboardingInExistingOrganization(api)}
        onCancel={() => setShowAddBusinessDialog(false)}
        confirmText="Confirm"
        cancelText="Cancel"
      />
    </>
  );
}
