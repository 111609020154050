import React from "react";
import { useNavigate } from "react-router";
import { useMutation } from "@tanstack/react-query";
import { ForgotPasswordForm } from "./ForgotPasswordForm";
import { Modal } from "@/components/Modal";
import { useAPI } from "@/api";
import { ResetPasswordResultPrompt } from "./ResetPasswordResultPrompt";
import { RequestResetPasswordResponseResultEnum } from "@norma-bi/bi-api";

export function ForgotPassword() {
  const navigate = useNavigate();

  const api = useAPI();

  const resetPassword = useMutation({
    mutationFn: (args: { accountNumber: string; email: string }) => {
      return api.postRequestResetPassword({
        RequestResetPasswordRequest: {
          business_ref: parseInt(args.accountNumber),
          email: args.email,
        },
      });
    },
  });

  const [resetPasswordResult, setResetPasswordResult] =
    React.useState<RequestResetPasswordResponseResultEnum | null>(null);

  async function onConfirm(accountNumber: string, email: string) {
    const { result } = await resetPassword.mutateAsync({ accountNumber, email });
    setResetPasswordResult(result);
  }

  async function onFinish() {
    navigate("..");
  }

  function onShowChanged(show: boolean) {
    if (!show) navigate("..");
  }

  function onCancel() {
    navigate("..");
  }

  return (
    <Modal show={true} onShowChanged={onShowChanged} dialogClassname={"w-[53%] max-w-[660px]"}>
      {resetPasswordResult ? (
        <ResetPasswordResultPrompt onFinish={onFinish} result={resetPasswordResult} />
      ) : (
        <ForgotPasswordForm
          onConfirm={onConfirm}
          isPending={resetPassword.isPending}
          onCancel={onCancel}
        />
      )}
    </Modal>
  );
}
