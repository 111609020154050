/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IDName } from './IDName';
import {
    IDNameFromJSON,
    IDNameFromJSONTyped,
    IDNameToJSON,
    IDNameToJSONTyped,
} from './IDName';
import type { BranchWithOrderCountAndNetSales } from './BranchWithOrderCountAndNetSales';
import {
    BranchWithOrderCountAndNetSalesFromJSON,
    BranchWithOrderCountAndNetSalesFromJSONTyped,
    BranchWithOrderCountAndNetSalesToJSON,
    BranchWithOrderCountAndNetSalesToJSONTyped,
} from './BranchWithOrderCountAndNetSales';

/**
 * 
 * @export
 * @interface BranchGroupWithNetSalesAndOrderCount
 */
export interface BranchGroupWithNetSalesAndOrderCount {
    /**
     * 
     * @type {IDName}
     * @memberof BranchGroupWithNetSalesAndOrderCount
     */
    group: IDName;
    /**
     * 
     * @type {Array<BranchWithOrderCountAndNetSales>}
     * @memberof BranchGroupWithNetSalesAndOrderCount
     */
    branches: Array<BranchWithOrderCountAndNetSales>;
}

/**
 * Check if a given object implements the BranchGroupWithNetSalesAndOrderCount interface.
 */
export function instanceOfBranchGroupWithNetSalesAndOrderCount(value: object): value is BranchGroupWithNetSalesAndOrderCount {
    if (!('group' in value) || value['group'] === undefined) return false;
    if (!('branches' in value) || value['branches'] === undefined) return false;
    return true;
}

export function BranchGroupWithNetSalesAndOrderCountFromJSON(json: any): BranchGroupWithNetSalesAndOrderCount {
    return BranchGroupWithNetSalesAndOrderCountFromJSONTyped(json, false);
}

export function BranchGroupWithNetSalesAndOrderCountFromJSONTyped(json: any, ignoreDiscriminator: boolean): BranchGroupWithNetSalesAndOrderCount {
    if (json == null) {
        return json;
    }
    return {
        
        'group': IDNameFromJSON(json['group']),
        'branches': ((json['branches'] as Array<any>).map(BranchWithOrderCountAndNetSalesFromJSON)),
    };
}

export function BranchGroupWithNetSalesAndOrderCountToJSON(json: any): BranchGroupWithNetSalesAndOrderCount {
    return BranchGroupWithNetSalesAndOrderCountToJSONTyped(json, false);
}

export function BranchGroupWithNetSalesAndOrderCountToJSONTyped(value?: BranchGroupWithNetSalesAndOrderCount | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'group': IDNameToJSON(value['group']),
        'branches': ((value['branches'] as Array<any>).map(BranchWithOrderCountAndNetSalesToJSON)),
    };
}

