import React from "react";
import { useLocation, useNavigate } from "react-router";
import { SearchBar } from "./SearchBar";
import { FavoriteToggle } from "./FavoriteToggle";
import { LiveboardList } from "./LiveboardList";
import { OldQuickSettings } from "@/components/OldQuickSettings/OldQuickSettings";
import { UserAggregateMetaNormaEnterpriseWebModeEnum as AppMode } from "@norma-bi/bi-api";
import { SvgIcon } from "../svg";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import clsx from "clsx";

type SidebarProps = {
  mode: AppMode;
  onToggleMode: () => void;
};

export function Sidebar({ mode, onToggleMode }: SidebarProps) {
  const [searchInput, setSearchInput] = React.useState("");
  const [showFavoritesOnly, setShowFavoritesOnly] = React.useState(false);
  const navigate = useNavigate();

  function onLiveboardSelected(liveboardId: string) {
    navigate(`/pinboard/${liveboardId}`);
  }
  const location = useLocation();
  const selectedLiveboardId = location.pathname.match(/\/pinboard\/([^/]+)/)?.[1] || null;

  const collapseTriggeringWidth = 768;

  const [isExpanded, setIsExpanded] = React.useState(true);
  React.useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (window.innerWidth < collapseTriggeringWidth) {
        setIsExpanded(false);
      } else if (window.innerWidth >= collapseTriggeringWidth) {
        setIsExpanded(true);
      }
    });

    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.unobserve(document.body);
      resizeObserver.disconnect();
    };
  }, [collapseTriggeringWidth]);

  function userTogglesExpansion() {
    setIsExpanded((curr) => {
      return !curr;
    });
  }

  function style(s: Partial<{ expanded: string; collapsed: string; default: string }>): string {
    return clsx(s.default, isExpanded ? s.expanded : s.collapsed);
  }

  const [autoAnimateContainer] = useAutoAnimate({ duration: 150 });

  return (
    <aside
      className={style({
        default:
          "relative max-w-[272px] pt-5 pl-4 flex flex-col h-full gap-4 transition-all duration-150",
        expanded: "w-[35%] md:w-[30%]",
        collapsed: "w-[68px]",
      })}
    >
      <button
        className="absolute top-[50%] -right-6 z-10 rounded-lg shadow-button p-3 bg-white text-feintGray hover:text-dark"
        onClick={userTogglesExpansion}
      >
        <div className={clsx(!isExpanded && "rotate-180")}>
          <SvgIcon icon={"DoubleChevronLeft"} />
        </div>
      </button>

      <div
        ref={autoAnimateContainer}
        className={style({
          default: "flex items-center select-none w-full",
          collapsed: "flex-col justify-center gap-3",
        })}
      >
        <OldQuickSettings
          onToggleMode={onToggleMode}
          mode={mode}
          hideArrows
          hideBusinessName={!isExpanded}
        />
        {isExpanded && <SearchBar searchInput={searchInput} setSearchInput={setSearchInput} />}
        <FavoriteToggle
          showFavoritesOnly={showFavoritesOnly}
          setShowFavoritesOnly={setShowFavoritesOnly}
        />
      </div>

      <nav className="sidebar-content-container flex-grow overflow-y-auto overflow-x-hidden text-sm">
        <LiveboardList
          selectedLiveboardId={selectedLiveboardId}
          searchTerm={searchInput}
          favoritesOnly={showFavoritesOnly}
          onLiveboardSelected={onLiveboardSelected}
          isSidebarExpanded={isExpanded}
        />
      </nav>
    </aside>
  );
}
