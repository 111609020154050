import React from "react";
import { SvgIcon } from "@/svg";
import { useNavigate, useLocation } from "react-router";
import { isAllBusiness } from "@/api";
import { ChangeModeRequestModeEnum as AppMode } from "@norma-bi/bi-api";
import { useGetBusinesses } from "@/hooks/useGetBusinesses";
import { AppModeToggle } from "./AppModeToggle";
import { useBusinessContext } from "@/hooks/useBusinessContext";
import { ROUTE_STATE_EMBED_SHOULD_IGNORE_ROUTE_CHANGE } from "@/constants";
import { OldBusinessAvatar } from "./OldBusinessAvatar";
import { OldBusinessPicker } from "./OldBusinessPicker";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import clsx from "clsx";
import { useAuth } from "@/Auth";
import { mergeUserEmails } from "@/utils/mergeUserEmails";

export type OldQuickSettingsProps = {
  onToggleMode: () => void;
  mode: AppMode;
  onPopupVisibilityChange?: (isVisible: boolean) => void;
  hideArrows?: boolean;
  hideBusinessName?: boolean;
};

export function OldQuickSettings({
  onToggleMode,
  mode,
  hideArrows = false,
  hideBusinessName = false,
}: OldQuickSettingsProps) {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = React.useCallback(() => {
    navigate(`${location.pathname}?logout`, {
      state: { [ROUTE_STATE_EMBED_SHOULD_IGNORE_ROUTE_CHANGE]: true },
    });
  }, [navigate, location.pathname]);

  const businesses = useGetBusinesses();

  const userEmails = React.useMemo(() => {
    if (!auth.isAuthenticated) {
      return "";
    }
    return mergeUserEmails(auth.user);
  }, [auth.isAuthenticated, auth.user]);

  const businessContext = useBusinessContext();

  function classNamePerMode(
    classNames: Partial<{ default: string; lite: string; pro: string }>,
  ): string {
    return clsx(classNames.default, mode === AppMode.lite ? classNames.lite : classNames.pro);
  }

  if (businessContext.isLoading) {
    return null;
  }

  return (
    <Popover
      className={classNamePerMode({
        default: "group",
        pro: "h-full border-b-2 border-b-transparent hover:border-b-white data-open:border-b-white",
        lite: "w-auto! h-auto! flex-1 shrink min-w-0", // w-auto and h-auto should be important, otherwise the animations break
      })}
    >
      {({ close }) => (
        <>
          <PopoverButton
            className={clsx(
              classNamePerMode({
                default: "h-full w-full flex space-x-3",
                lite: "justify-between",
              }),
              classNamePerMode({
                default: "flex items-center",
                lite: "font-medium text-feintGray group-hover:text-dark group-data-open:text-dark",
                pro: "text-light group-hover:text-white group-data-open:text-white flex items-center",
              }),
            )}
          >
            <OldBusinessAvatar
              businessDisplayName={businessContext.business.display_name}
              className={isAllBusiness(businessContext.business.id) ? "bg-cyan" : "bg-primaryColor"}
            />

            {!hideBusinessName && (
              <div
                className="text-sm text-left truncate flex-1"
                title={businessContext.business.display_name}
              >
                {businessContext.business.display_name}
              </div>
            )}
            {!hideArrows && (
              <div>
                <SvgIcon icon="UpDownArrows" />
              </div>
            )}
          </PopoverButton>

          <PopoverPanel
            anchor="bottom start"
            transition
            className={clsx(
              "bg-white font-medium text-feintGray rounded-2xl shadow-xl w-96 flex flex-col",
              "transition origin-top duration-200 data-closed:scale-95 data-closed:opacity-0",
              "[--anchor-gap:0.3rem] [--anchor-max-height:70vh]",
            )}
          >
            <div className="flex items-center justify-between p-4">
              <div className="text-sm truncate max-w-[170px]" title={userEmails}>
                {userEmails}
              </div>
              <div className="flex items-center text-sm gap-4">
                <button className="cursor-pointer hover:text-primaryColor" onClick={handleLogout}>
                  Log out
                </button>
              </div>
            </div>

            <OldBusinessPicker businesses={businesses} onClose={close} />

            <AppModeToggle mode={mode} onToggleMode={onToggleMode} />
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
}
