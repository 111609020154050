/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrderTag } from './OrderTag';
import {
    OrderTagFromJSON,
    OrderTagFromJSONTyped,
    OrderTagToJSON,
    OrderTagToJSONTyped,
} from './OrderTag';

/**
 * 
 * @export
 * @interface OrderTagWithNetSales
 */
export interface OrderTagWithNetSales {
    /**
     * 
     * @type {OrderTag}
     * @memberof OrderTagWithNetSales
     */
    order_tag: OrderTag;
    /**
     * 
     * @type {number}
     * @memberof OrderTagWithNetSales
     */
    net_sales: number;
}

/**
 * Check if a given object implements the OrderTagWithNetSales interface.
 */
export function instanceOfOrderTagWithNetSales(value: object): value is OrderTagWithNetSales {
    if (!('order_tag' in value) || value['order_tag'] === undefined) return false;
    if (!('net_sales' in value) || value['net_sales'] === undefined) return false;
    return true;
}

export function OrderTagWithNetSalesFromJSON(json: any): OrderTagWithNetSales {
    return OrderTagWithNetSalesFromJSONTyped(json, false);
}

export function OrderTagWithNetSalesFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderTagWithNetSales {
    if (json == null) {
        return json;
    }
    return {
        
        'order_tag': OrderTagFromJSON(json['order_tag']),
        'net_sales': json['net_sales'],
    };
}

export function OrderTagWithNetSalesToJSON(json: any): OrderTagWithNetSales {
    return OrderTagWithNetSalesToJSONTyped(json, false);
}

export function OrderTagWithNetSalesToJSONTyped(value?: OrderTagWithNetSales | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'order_tag': OrderTagToJSON(value['order_tag']),
        'net_sales': value['net_sales'],
    };
}

