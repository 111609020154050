export function FileNote() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="file-note">
        <path
          id="Icon"
          d="M9.33317 7.33203H5.33317M6.6665 9.9987H5.33317M10.6665 4.66536H5.33317M13.3332 6.66536V4.53203C13.3332 3.41193 13.3332 2.85187 13.1152 2.42405C12.9234 2.04773 12.6175 1.74176 12.2412 1.55002C11.8133 1.33203 11.2533 1.33203 10.1332 1.33203H5.8665C4.7464 1.33203 4.18635 1.33203 3.75852 1.55002C3.3822 1.74176 3.07624 2.04773 2.88449 2.42405C2.6665 2.85187 2.6665 3.41193 2.6665 4.53203V11.4654C2.6665 12.5855 2.6665 13.1455 2.88449 13.5733C3.07624 13.9497 3.3822 14.2556 3.75852 14.4474C4.18635 14.6654 4.7464 14.6654 5.8665 14.6654H8.33317M11.9998 13.9987C11.9998 13.9987 13.9998 13.0454 13.9998 11.6155V9.94729L12.5414 9.42617C12.191 9.30065 11.8078 9.30065 11.4574 9.42617L9.99984 9.94729V11.6155C9.99984 13.0454 11.9998 13.9987 11.9998 13.9987Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
