/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BranchWithNetSales } from './BranchWithNetSales';
import {
    BranchWithNetSalesFromJSON,
    BranchWithNetSalesFromJSONTyped,
    BranchWithNetSalesToJSON,
    BranchWithNetSalesToJSONTyped,
} from './BranchWithNetSales';

/**
 * 
 * @export
 * @interface MenuMixBusinessProductCategoryBranchesWithNetSalesResponse
 */
export interface MenuMixBusinessProductCategoryBranchesWithNetSalesResponse {
    /**
     * 
     * @type {Array<BranchWithNetSales>}
     * @memberof MenuMixBusinessProductCategoryBranchesWithNetSalesResponse
     */
    data: Array<BranchWithNetSales>;
}

/**
 * Check if a given object implements the MenuMixBusinessProductCategoryBranchesWithNetSalesResponse interface.
 */
export function instanceOfMenuMixBusinessProductCategoryBranchesWithNetSalesResponse(value: object): value is MenuMixBusinessProductCategoryBranchesWithNetSalesResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function MenuMixBusinessProductCategoryBranchesWithNetSalesResponseFromJSON(json: any): MenuMixBusinessProductCategoryBranchesWithNetSalesResponse {
    return MenuMixBusinessProductCategoryBranchesWithNetSalesResponseFromJSONTyped(json, false);
}

export function MenuMixBusinessProductCategoryBranchesWithNetSalesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MenuMixBusinessProductCategoryBranchesWithNetSalesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(BranchWithNetSalesFromJSON)),
    };
}

export function MenuMixBusinessProductCategoryBranchesWithNetSalesResponseToJSON(json: any): MenuMixBusinessProductCategoryBranchesWithNetSalesResponse {
    return MenuMixBusinessProductCategoryBranchesWithNetSalesResponseToJSONTyped(json, false);
}

export function MenuMixBusinessProductCategoryBranchesWithNetSalesResponseToJSONTyped(value?: MenuMixBusinessProductCategoryBranchesWithNetSalesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(BranchWithNetSalesToJSON)),
    };
}

