import React from "react";
import { useAPI } from "@/api";
import { SvgIcon } from "@/svg";
import { useBudgetContext } from "./useBudgetContext";
import { useBusinessContext } from "@/hooks/useBusinessContext";
import * as Sentry from "@sentry/react";
import { GetBudgetResolutionEnum } from "@norma-bi/bi-api";

type FileDownloadProps = {
  resolution: (typeof GetBudgetResolutionEnum)[keyof typeof GetBudgetResolutionEnum];
  className?: string;
};

function FileDownloadButton({ resolution, className }: FileDownloadProps) {
  const api = useAPI();
  const { selectedYear } = useBudgetContext();
  const { business } = useBusinessContext();

  const displayText =
    resolution === GetBudgetResolutionEnum.weekly ? "Weekly_version.xlsx" : "Monthly_version.xlsx";

  const handleDownload = async (e: React.MouseEvent) => {
    e.preventDefault();

    if (!business?.id) {
      console.error("No business selected");
      Sentry.captureException(
        new Error("Attempted to download budget file without business selected"),
        {
          tags: {
            component: "FileDownloadButton",
            action: "handleDownload",
          },
          extra: {
            resolution,
            selectedYear,
            business,
          },
        },
      );
      return;
    }

    let url: string | undefined;
    let link: HTMLAnchorElement | undefined;

    try {
      const blob = await api.getBudgetXlsx({
        resolution,
        year: selectedYear,
        business_id: business.id,
      });

      url = window.URL.createObjectURL(blob);
      link = document.createElement("a");
      link.href = url;
      link.download = `${resolution}-budget-${selectedYear}.xlsx`;
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      if (error instanceof Error) {
        Sentry.captureException(error, {
          tags: {
            component: "FileDownloadButton",
            action: "handleDownload",
          },
          extra: {
            resolution,
            selectedYear,
            businessId: business.id,
            requestDetails: {
              business_id: business.id,
              resolution,
              year: selectedYear,
            },
          },
        });
        console.error("Error during file download:", error);
      }
    } finally {
      if (link) {
        document.body.removeChild(link);
      }
      if (url) {
        window.URL.revokeObjectURL(url);
      }
    }
  };

  return (
    <div className={`flex items-center ${className}`}>
      <div className="flex items-center cursor-pointer" onClick={handleDownload}>
        <SvgIcon icon="Download" />
        <a
          href="#"
          className="text-primaryColor hover:underline ml-2"
          onClick={(e) => e.preventDefault()}
        >
          {displayText}
        </a>
      </div>
    </div>
  );
}

export function CurrentFilesDownloadSection() {
  return (
    <div className="flex items-center px-12 py-10 gap-x-8">
      <span className="text-feintGray">Your Current Files</span>
      <div className="flex items-center">
        <FileDownloadButton resolution={GetBudgetResolutionEnum.weekly} />
        <FileDownloadButton resolution={GetBudgetResolutionEnum.monthly} className="ml-6" />
      </div>
    </div>
  );
}
