/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Metric } from './Metric';
import {
    MetricFromJSON,
    MetricFromJSONTyped,
    MetricToJSON,
    MetricToJSONTyped,
} from './Metric';

/**
 * 
 * @export
 * @interface BusinessReportOrderTypesNetSalesResponse
 */
export interface BusinessReportOrderTypesNetSalesResponse {
    /**
     * 
     * @type {Array<Metric>}
     * @memberof BusinessReportOrderTypesNetSalesResponse
     */
    data: Array<Metric>;
}

/**
 * Check if a given object implements the BusinessReportOrderTypesNetSalesResponse interface.
 */
export function instanceOfBusinessReportOrderTypesNetSalesResponse(value: object): value is BusinessReportOrderTypesNetSalesResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function BusinessReportOrderTypesNetSalesResponseFromJSON(json: any): BusinessReportOrderTypesNetSalesResponse {
    return BusinessReportOrderTypesNetSalesResponseFromJSONTyped(json, false);
}

export function BusinessReportOrderTypesNetSalesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessReportOrderTypesNetSalesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(MetricFromJSON)),
    };
}

export function BusinessReportOrderTypesNetSalesResponseToJSON(json: any): BusinessReportOrderTypesNetSalesResponse {
    return BusinessReportOrderTypesNetSalesResponseToJSONTyped(json, false);
}

export function BusinessReportOrderTypesNetSalesResponseToJSONTyped(value?: BusinessReportOrderTypesNetSalesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(MetricToJSON)),
    };
}

