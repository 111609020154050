import {
  DefaultApi,
  Configuration,
  DefaultApiInterface,
  ErrorContext,
  ConfigurationParameters,
} from "./gen";

export interface AuthenticatedAPIInterface extends DefaultApiInterface {
  isAuthenticated: boolean;
}

class AuthenticatedAPI extends DefaultApi implements AuthenticatedAPIInterface {
  isAuthenticated: boolean;
  constructor(configuration?: Configuration) {
    super(configuration);
    const accessToken = configuration?.accessToken;
    if (accessToken) {
      this.isAuthenticated = Boolean(
        typeof accessToken === "function" ? accessToken() : accessToken,
      );
    } else {
      this.isAuthenticated = false;
    }
  }
}

export class NotOKResponseError extends Error {
  url: string;
  response: Response;

  constructor(url: string, response: Response) {
    super("not-ok-response");
    this.url = url;
    this.response = response;
  }
}

export class GeneralAPIError extends Error {
  context: ErrorContext;
  constructor(context: ErrorContext) {
    super("general-api-error");
    this.context = context;
  }
}

export type APIError = NotOKResponseError | GeneralAPIError;

export const createAuthenticatedAPI = ({
  accessToken,
  basePath,
  logging,
  onError,
  headers,
}: {
  accessToken: ConfigurationParameters["accessToken"];
  basePath: string;
  logging?: boolean;
  onError: (err: APIError) => Promise<any>;
  headers?: Headers;
}): AuthenticatedAPIInterface => {
  const conf = new Configuration({
    accessToken,
    basePath,
    middleware: [
      {
        pre: (ctx) => {
          if (logging) {
            console.log(
              JSON.stringify({
                time: new Date(),
                type: "request",
                url: ctx.url,
              }),
            );
          }
          ctx.init.headers = mergeHeaders(ctx.init.headers, headers);
          return Promise.resolve(ctx);
        },
        post: async ({ url, response }) => {
          if (!response.ok) {
            onError(new NotOKResponseError(url, response));
          }
          if (logging) {
            let body = null;
            try {
              body = await response.json();
            } catch (_e) {}
            console.log(
              JSON.stringify({
                time: new Date(),
                type: "response",
                status: response.status,
                url,
                body,
              }),
            );
          }
        },
        onError(context: ErrorContext): Promise<Response | void> {
          return onError(new GeneralAPIError(context));
        },
      },
    ],
  });

  return new AuthenticatedAPI(conf);
};

function mergeHeaders(a?: HeadersInit, b?: HeadersInit): HeadersInit | undefined {
  const ret = new Headers(a);
  const c = new Headers(b);
  c.forEach((value: string, name: string) => {
    ret.set(name, value);
  });
  return ret;
}
