/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
    ProductToJSONTyped,
} from './Product';

/**
 * 
 * @export
 * @interface ProductWithNetSalesAndQuantity
 */
export interface ProductWithNetSalesAndQuantity {
    /**
     * 
     * @type {Product}
     * @memberof ProductWithNetSalesAndQuantity
     */
    product: Product;
    /**
     * 
     * @type {number}
     * @memberof ProductWithNetSalesAndQuantity
     */
    net_sales: number;
    /**
     * 
     * @type {number}
     * @memberof ProductWithNetSalesAndQuantity
     */
    quantity: number;
}

/**
 * Check if a given object implements the ProductWithNetSalesAndQuantity interface.
 */
export function instanceOfProductWithNetSalesAndQuantity(value: object): value is ProductWithNetSalesAndQuantity {
    if (!('product' in value) || value['product'] === undefined) return false;
    if (!('net_sales' in value) || value['net_sales'] === undefined) return false;
    if (!('quantity' in value) || value['quantity'] === undefined) return false;
    return true;
}

export function ProductWithNetSalesAndQuantityFromJSON(json: any): ProductWithNetSalesAndQuantity {
    return ProductWithNetSalesAndQuantityFromJSONTyped(json, false);
}

export function ProductWithNetSalesAndQuantityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductWithNetSalesAndQuantity {
    if (json == null) {
        return json;
    }
    return {
        
        'product': ProductFromJSON(json['product']),
        'net_sales': json['net_sales'],
        'quantity': json['quantity'],
    };
}

export function ProductWithNetSalesAndQuantityToJSON(json: any): ProductWithNetSalesAndQuantity {
    return ProductWithNetSalesAndQuantityToJSONTyped(json, false);
}

export function ProductWithNetSalesAndQuantityToJSONTyped(value?: ProductWithNetSalesAndQuantity | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'product': ProductToJSON(value['product']),
        'net_sales': value['net_sales'],
        'quantity': value['quantity'],
    };
}

