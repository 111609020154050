import { useAPI } from "@/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LiveboardsQueryKey } from "@/hooks/useLiveboards";
import { useThoughtSpotToken } from "@/thoughtspot";
import { createThoughtSpotHeaders } from "@/api/thoughtspot";

export function useFavoriteLiveboard() {
  const api = useAPI();
  const queryClient = useQueryClient();
  const thoughtspotToken = useThoughtSpotToken((state) => state.token);

  return useMutation({
    mutationFn: (args: { liveboardId: string; isFavorite: boolean }) => {
      if (!thoughtspotToken) {
        throw new Error("ThoughtSpot token is required");
      }

      if (args.isFavorite) {
        return api.unfavoriteThoughtspotLiveboard(
          { liveboard_id: args.liveboardId },
          createThoughtSpotHeaders(thoughtspotToken!),
        );
      }
      return api.favoriteThoughtspotLiveboard(
        { liveboard_id: args.liveboardId },
        createThoughtSpotHeaders(thoughtspotToken!),
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [LiveboardsQueryKey] });
    },
  });
}
