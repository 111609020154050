import { useQuery } from "@tanstack/react-query";
import { useAPI } from "@/api";

export function useGetBusinesses() {
  const api = useAPI();

  return useQuery({
    queryKey: ["businesses"],
    queryFn: async () => {
      const resp = await api.getBusinesses();
      return resp.data;
    },
    enabled: api.isAuthenticated,
    staleTime: Infinity,
  });
}
