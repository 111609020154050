import { useState } from "react";
import { useToggleAppMode } from "./useToggleAppMode";

export function useSwitchModeDialog() {
  const { mode, toggleAppMode } = useToggleAppMode();
  const [showSwitchDialog, setShowSwitchDialog] = useState(false);

  return {
    mode,
    showSwitchDialog,
    setShowSwitchDialog,
    toggleAppMode,
  };
}
