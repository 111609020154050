import React from "react";
import { useAPI } from "@/api";
import { SvgIcon } from "@/svg";
import { useBudgetContext } from "./useBudgetContext";
import { useBusinessContext } from "@/hooks/useBusinessContext";
import * as Sentry from "@sentry/react";
import { GetBudgetResolutionEnum } from "@norma-bi/bi-api";

const TEMPLATE_FILES_OPTIONS = [
  { id: GetBudgetResolutionEnum.monthly, label: "Monthly", fileName: "monthly-budget" },
  { id: GetBudgetResolutionEnum.weekly, label: "Weekly", fileName: "weekly-budget" },
] as const;

const getResolutionLabel = (resolution: GetBudgetResolutionEnum): string => {
  return TEMPLATE_FILES_OPTIONS.find((option) => option.id === resolution)?.label ?? resolution;
};

const getResolutionFileName = (resolution: GetBudgetResolutionEnum): string => {
  return TEMPLATE_FILES_OPTIONS.find((option) => option.id === resolution)?.fileName ?? resolution;
};

type TemplateDownloadProps = {
  resolution: GetBudgetResolutionEnum;
  className?: string;
  year: number;
};

function TemplateDownloadButton({ resolution, className }: TemplateDownloadProps) {
  const api = useAPI();
  const { selectedYear } = useBudgetContext();
  const { business } = useBusinessContext();

  const handleDownload = async (e: React.MouseEvent) => {
    e.preventDefault();

    if (!business?.id) {
      console.error("No business selected");
      Sentry.captureException(
        new Error("Attempted to download budget template without business selected"),
        {
          tags: {
            component: "TemplateDownloadButton",
            action: "handleDownload",
          },
          extra: {
            resolution,
            selectedYear,
            business,
          },
        },
      );
      return;
    }

    let url: string | undefined;
    let link: HTMLAnchorElement | undefined;

    try {
      const blob = await api.getBudgetTemplate({
        resolution,
        year: selectedYear,
        business_id: business.id,
      });

      url = window.URL.createObjectURL(blob);
      link = document.createElement("a");
      link.href = url;
      link.download = `${getResolutionFileName(resolution)}-template-${selectedYear}.xlsx`;
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      if (error instanceof Error) {
        Sentry.captureException(error, {
          tags: {
            component: "TemplateDownloadButton",
            action: "handleDownload",
          },
          extra: {
            resolution,
            selectedYear,
            businessId: business.id,
            requestDetails: {
              business_id: business.id,
              resolution,
              year: selectedYear,
            },
          },
        });
        console.error("Error during template download:", error);
      }
    } finally {
      if (link) {
        document.body.removeChild(link);
      }
      if (url) {
        window.URL.revokeObjectURL(url);
      }
    }
  };

  return (
    <div className={`flex items-center ${className}`}>
      <div className="flex items-center cursor-pointer" onClick={handleDownload}>
        <SvgIcon icon="Download" />
        <a
          href="#"
          className="text-primaryColor hover:underline ml-2"
          onClick={(e) => e.preventDefault()}
        >
          {getResolutionLabel(resolution)}
        </a>
      </div>
    </div>
  );
}

export function TemplateDownloadSection() {
  const { selectedYear } = useBudgetContext();

  return (
    <div className="flex items-center px-12 py-10 gap-x-8">
      <span className="text-feintGray">XLSX Template Files</span>
      <div className="flex items-center">
        <TemplateDownloadButton resolution={GetBudgetResolutionEnum.weekly} year={selectedYear} />
        <TemplateDownloadButton
          resolution={GetBudgetResolutionEnum.monthly}
          className="ml-6"
          year={selectedYear}
        />
      </div>
    </div>
  );
}
