/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BranchGroupWithNetSalesAndOrderCount } from './BranchGroupWithNetSalesAndOrderCount';
import {
    BranchGroupWithNetSalesAndOrderCountFromJSON,
    BranchGroupWithNetSalesAndOrderCountFromJSONTyped,
    BranchGroupWithNetSalesAndOrderCountToJSON,
    BranchGroupWithNetSalesAndOrderCountToJSONTyped,
} from './BranchGroupWithNetSalesAndOrderCount';

/**
 * 
 * @export
 * @interface BusinessReportBranchesWithNetSalesAndOrderCountPerOrderSourceResponse
 */
export interface BusinessReportBranchesWithNetSalesAndOrderCountPerOrderSourceResponse {
    /**
     * 
     * @type {Array<BranchGroupWithNetSalesAndOrderCount>}
     * @memberof BusinessReportBranchesWithNetSalesAndOrderCountPerOrderSourceResponse
     */
    data: Array<BranchGroupWithNetSalesAndOrderCount>;
}

/**
 * Check if a given object implements the BusinessReportBranchesWithNetSalesAndOrderCountPerOrderSourceResponse interface.
 */
export function instanceOfBusinessReportBranchesWithNetSalesAndOrderCountPerOrderSourceResponse(value: object): value is BusinessReportBranchesWithNetSalesAndOrderCountPerOrderSourceResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function BusinessReportBranchesWithNetSalesAndOrderCountPerOrderSourceResponseFromJSON(json: any): BusinessReportBranchesWithNetSalesAndOrderCountPerOrderSourceResponse {
    return BusinessReportBranchesWithNetSalesAndOrderCountPerOrderSourceResponseFromJSONTyped(json, false);
}

export function BusinessReportBranchesWithNetSalesAndOrderCountPerOrderSourceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BusinessReportBranchesWithNetSalesAndOrderCountPerOrderSourceResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(BranchGroupWithNetSalesAndOrderCountFromJSON)),
    };
}

export function BusinessReportBranchesWithNetSalesAndOrderCountPerOrderSourceResponseToJSON(json: any): BusinessReportBranchesWithNetSalesAndOrderCountPerOrderSourceResponse {
    return BusinessReportBranchesWithNetSalesAndOrderCountPerOrderSourceResponseToJSONTyped(json, false);
}

export function BusinessReportBranchesWithNetSalesAndOrderCountPerOrderSourceResponseToJSONTyped(value?: BusinessReportBranchesWithNetSalesAndOrderCountPerOrderSourceResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(BranchGroupWithNetSalesAndOrderCountToJSON)),
    };
}

