export function isAccountNumberValid(accountNumber: string): boolean {
  const matches = /^-?[0-9]{6}$/.test(accountNumber);
  return matches;
}

export function isBusinessRefValid(businessRef: string) {
  return (
    (businessRef.length === 6 || (businessRef.length === 7 && businessRef[0] === "-")) &&
    !isNaN(Number(businessRef))
  );
}

export function isEmailValid(email: string): boolean {
  const matches = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
  return matches !== null && matches.length > 0;
}
