import clsx from "clsx";
import { useToggleAppMode } from "@/hooks/useToggleAppMode";
import { ChangeModeRequestModeEnum as AppMode } from "@norma-bi/bi-api";

export type NewBusinessAvatarProps = {
  businessDisplayName: string;
  className?: string;
};

export function BusinessAvatar(props: NewBusinessAvatarProps) {
  const { mode } = useToggleAppMode();

  return (
    <div
      className={clsx(
        "w-8 h-8 cursor-pointer aspect-square",
        "flex items-center justify-center",
        "text-white font-medium text-sm",
        mode === AppMode.lite ? "rounded-lg" : "rounded-full",
        props.className,
      )}
    >
      {props.businessDisplayName.charAt(0).toUpperCase()}
    </div>
  );
}
