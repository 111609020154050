import { useQuery } from "@tanstack/react-query";
import { useAPI } from "@/api";
import { useThoughtSpotToken } from "@/thoughtspot";
import { createThoughtSpotHeaders } from "@/api/thoughtspot";

export function useSearchDataFeatureDataSources() {
  const api = useAPI();
  const thoughtspotToken = useThoughtSpotToken((state) => state.token);

  return useQuery({
    queryKey: ["search-data-data-sources"],
    queryFn: async () => {
      const resp = await api.getThoughtspotSearchDataDataSources(
        createThoughtSpotHeaders(thoughtspotToken!),
      );
      return resp.data;
    },
    enabled: api.isAuthenticated && !!thoughtspotToken,
  });
}
