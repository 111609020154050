import { useQuery } from "@tanstack/react-query";
import { useAPI } from "@/api";
import { useThoughtSpotToken } from "@/thoughtspot";
import { createThoughtSpotHeaders } from "@/api/thoughtspot";

export const EmailSchedulesQueryKey = "emailSchedules" as const;

export function useGetEmailSchedules() {
  const api = useAPI();
  const { token: thoughtspotToken } = useThoughtSpotToken();

  return useQuery({
    queryKey: [EmailSchedulesQueryKey, thoughtspotToken],
    queryFn: async () => {
      const r = await api.getThoughtspotSchedules(createThoughtSpotHeaders(thoughtspotToken!));
      return r.data;
    },
    enabled: api.isAuthenticated && !!thoughtspotToken,
  });
}
