import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAPI } from "@/api";
import { UpdateBusinessRequest } from "@norma-bi/bi-api";

export function useUpdateBusiness() {
  const api = useAPI();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (args: { businessId: string; changeset: UpdateBusinessRequest }) => {
      return api.patchBusinessesBusinessId({
        business_id: args.businessId,
        UpdateBusinessRequest: args.changeset,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["businesses"] });
    },
  });
}
