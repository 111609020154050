import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAPI } from "@/api";

export function usePromoteUser() {
  const queryClient = useQueryClient();
  const api = useAPI();

  return useMutation({
    mutationFn: (args: { userId: string }) => {
      return api.usersPromoteUser({ user_id: args.userId });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });
}
