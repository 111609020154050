/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Metric } from './Metric';
import {
    MetricFromJSON,
    MetricFromJSONTyped,
    MetricToJSON,
    MetricToJSONTyped,
} from './Metric';

/**
 * 
 * @export
 * @interface BranchOrderSourcesNetSalesResponse
 */
export interface BranchOrderSourcesNetSalesResponse {
    /**
     * 
     * @type {Array<Metric>}
     * @memberof BranchOrderSourcesNetSalesResponse
     */
    data: Array<Metric>;
}

/**
 * Check if a given object implements the BranchOrderSourcesNetSalesResponse interface.
 */
export function instanceOfBranchOrderSourcesNetSalesResponse(value: object): value is BranchOrderSourcesNetSalesResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function BranchOrderSourcesNetSalesResponseFromJSON(json: any): BranchOrderSourcesNetSalesResponse {
    return BranchOrderSourcesNetSalesResponseFromJSONTyped(json, false);
}

export function BranchOrderSourcesNetSalesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BranchOrderSourcesNetSalesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(MetricFromJSON)),
    };
}

export function BranchOrderSourcesNetSalesResponseToJSON(json: any): BranchOrderSourcesNetSalesResponse {
    return BranchOrderSourcesNetSalesResponseToJSONTyped(json, false);
}

export function BranchOrderSourcesNetSalesResponseToJSONTyped(value?: BranchOrderSourcesNetSalesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(MetricToJSON)),
    };
}

