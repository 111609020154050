/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LicenseCategory } from './LicenseCategory';
import {
    LicenseCategoryFromJSON,
    LicenseCategoryFromJSONTyped,
    LicenseCategoryToJSON,
    LicenseCategoryToJSONTyped,
} from './LicenseCategory';

/**
 * 
 * @export
 * @interface Licenses
 */
export interface Licenses {
    /**
     * 
     * @type {Date}
     * @memberof Licenses
     */
    account_signup_at?: Date;
    /**
     * 
     * @type {string}
     * @memberof Licenses
     */
    owner_email?: string;
    /**
     * 
     * @type {string}
     * @memberof Licenses
     */
    business_plan?: string;
    /**
     * 
     * @type {Array<LicenseCategory>}
     * @memberof Licenses
     */
    license_categories: Array<LicenseCategory>;
}

/**
 * Check if a given object implements the Licenses interface.
 */
export function instanceOfLicenses(value: object): value is Licenses {
    if (!('license_categories' in value) || value['license_categories'] === undefined) return false;
    return true;
}

export function LicensesFromJSON(json: any): Licenses {
    return LicensesFromJSONTyped(json, false);
}

export function LicensesFromJSONTyped(json: any, ignoreDiscriminator: boolean): Licenses {
    if (json == null) {
        return json;
    }
    return {
        
        'account_signup_at': json['account_signup_at'] == null ? undefined : (new Date(json['account_signup_at'])),
        'owner_email': json['owner_email'] == null ? undefined : json['owner_email'],
        'business_plan': json['business_plan'] == null ? undefined : json['business_plan'],
        'license_categories': ((json['license_categories'] as Array<any>).map(LicenseCategoryFromJSON)),
    };
}

export function LicensesToJSON(json: any): Licenses {
    return LicensesToJSONTyped(json, false);
}

export function LicensesToJSONTyped(value?: Licenses | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'account_signup_at': value['account_signup_at'] == null ? undefined : ((value['account_signup_at']).toISOString()),
        'owner_email': value['owner_email'],
        'business_plan': value['business_plan'],
        'license_categories': ((value['license_categories'] as Array<any>).map(LicenseCategoryToJSON)),
    };
}

