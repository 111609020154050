/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BranchWithNetSalesAndQuantity } from './BranchWithNetSalesAndQuantity';
import {
    BranchWithNetSalesAndQuantityFromJSON,
    BranchWithNetSalesAndQuantityFromJSONTyped,
    BranchWithNetSalesAndQuantityToJSON,
    BranchWithNetSalesAndQuantityToJSONTyped,
} from './BranchWithNetSalesAndQuantity';

/**
 * 
 * @export
 * @interface MenuMixBusinessProductBranchesWithNetSalesAndQuantityResponse
 */
export interface MenuMixBusinessProductBranchesWithNetSalesAndQuantityResponse {
    /**
     * 
     * @type {Array<BranchWithNetSalesAndQuantity>}
     * @memberof MenuMixBusinessProductBranchesWithNetSalesAndQuantityResponse
     */
    data: Array<BranchWithNetSalesAndQuantity>;
}

/**
 * Check if a given object implements the MenuMixBusinessProductBranchesWithNetSalesAndQuantityResponse interface.
 */
export function instanceOfMenuMixBusinessProductBranchesWithNetSalesAndQuantityResponse(value: object): value is MenuMixBusinessProductBranchesWithNetSalesAndQuantityResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function MenuMixBusinessProductBranchesWithNetSalesAndQuantityResponseFromJSON(json: any): MenuMixBusinessProductBranchesWithNetSalesAndQuantityResponse {
    return MenuMixBusinessProductBranchesWithNetSalesAndQuantityResponseFromJSONTyped(json, false);
}

export function MenuMixBusinessProductBranchesWithNetSalesAndQuantityResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MenuMixBusinessProductBranchesWithNetSalesAndQuantityResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(BranchWithNetSalesAndQuantityFromJSON)),
    };
}

export function MenuMixBusinessProductBranchesWithNetSalesAndQuantityResponseToJSON(json: any): MenuMixBusinessProductBranchesWithNetSalesAndQuantityResponse {
    return MenuMixBusinessProductBranchesWithNetSalesAndQuantityResponseToJSONTyped(json, false);
}

export function MenuMixBusinessProductBranchesWithNetSalesAndQuantityResponseToJSONTyped(value?: MenuMixBusinessProductBranchesWithNetSalesAndQuantityResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(BranchWithNetSalesAndQuantityToJSON)),
    };
}

