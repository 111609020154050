/* eslint-disable react-refresh/only-export-components */
import React from "react";
import {
  APIError,
  AuthenticatedAPIInterface,
  NotOKResponseError,
  OnboardingProgress,
  OnboardingProgressSSEEmitter,
  SSEEmitterInterface,
  createAuthenticatedAPI,
} from "@norma-bi/bi-api";
import { BASE_URL } from "../constants";
import { useAccessToken } from "../Auth/useAccessToken";
import * as Sentry from "@sentry/react";
import { useInitialOnboardingToken } from "../pages/Onboarding/InitialOnboarding/useInitialOnboardingToken";

async function reportError(err: APIError) {
  if (err instanceof NotOKResponseError) {
    let body: string | null = null;
    try {
      body = await err.response.text();
    } catch (e) {
      console.error("could not get response.text()", e);
    }
    Sentry.captureEvent({
      level: "error",
      message: "Not ok response",
      tags: {
        url: err.url,
        status: err.response.status,
      },
      extra: {
        body,
      },
    });
    return;
  }

  const resp = err.context.response;
  let body: string | null = null;
  if (resp) {
    try {
      body = await resp.text();
    } catch (e) {
      console.error("could not get response.text()", e);
    }
  }

  Sentry.captureException(err.context.error, {
    tags: {
      url: err.context.url,
      status: err.context.response?.status,
    },
    extra: {
      body,
    },
  });
}

export const apiContext = React.createContext<AuthenticatedAPIInterface>(
  createAuthenticatedAPI({
    basePath: BASE_URL,
    accessToken: undefined,
    onError: reportError,
  }),
);

export type APIFactoryParams = Partial<{
  initialOnboardingToken: string;
  accessToken: string;
}>;

export function apiFactory({ initialOnboardingToken, accessToken }: APIFactoryParams) {
  function accessTokenFn(securityName?: string) {
    if (securityName === "Foodics_Initial_Onboarding") {
      return initialOnboardingToken ?? "";
    }
    return accessToken ?? "";
  }

  return createAuthenticatedAPI({
    accessToken: accessTokenFn,
    basePath: BASE_URL,
    onError: reportError,
  });
}

export function APIProvider(props: React.PropsWithChildren) {
  const { accessToken } = useAccessToken();
  const { token: initialOnboardingToken } = useInitialOnboardingToken();

  const api = React.useMemo(() => {
    return apiFactory({
      accessToken,
      initialOnboardingToken,
    });
  }, [accessToken, initialOnboardingToken]);

  return <apiContext.Provider {...props} value={api} />;
}

export function useAPI() {
  return React.useContext(apiContext);
}

export type OnboardingProgressEmitterContext =
  | {
      isAuthenticated: true;
      emitter: SSEEmitterInterface<OnboardingProgress>;
    }
  | {
      isAuthenticated: false;
      emitter: null;
    };

export const onboardingProgressEmitterContext =
  React.createContext<OnboardingProgressEmitterContext>({
    isAuthenticated: false,
    emitter: null,
  });

export function OnboardingProgressEmitterProvider(props: React.PropsWithChildren) {
  const { accessToken } = useAccessToken();

  const value = React.useMemo<OnboardingProgressEmitterContext>(() => {
    if (!accessToken) {
      return {
        isAuthenticated: false,
        emitter: null,
      };
    }
    return {
      isAuthenticated: true,
      emitter: new OnboardingProgressSSEEmitter(BASE_URL, accessToken),
    };
  }, [accessToken]);

  return <onboardingProgressEmitterContext.Provider {...props} value={value} />;
}

export function useOnboardingProgressEmitter() {
  return React.useContext(onboardingProgressEmitterContext);
}
