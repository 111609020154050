/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Branch } from './Branch';
import {
    BranchFromJSON,
    BranchFromJSONTyped,
    BranchToJSON,
    BranchToJSONTyped,
} from './Branch';

/**
 * 
 * @export
 * @interface BranchWithOrderCountAndNetSales
 */
export interface BranchWithOrderCountAndNetSales {
    /**
     * 
     * @type {Branch}
     * @memberof BranchWithOrderCountAndNetSales
     */
    branch: Branch;
    /**
     * 
     * @type {number}
     * @memberof BranchWithOrderCountAndNetSales
     */
    order_count: number;
    /**
     * 
     * @type {number}
     * @memberof BranchWithOrderCountAndNetSales
     */
    net_sales: number;
}

/**
 * Check if a given object implements the BranchWithOrderCountAndNetSales interface.
 */
export function instanceOfBranchWithOrderCountAndNetSales(value: object): value is BranchWithOrderCountAndNetSales {
    if (!('branch' in value) || value['branch'] === undefined) return false;
    if (!('order_count' in value) || value['order_count'] === undefined) return false;
    if (!('net_sales' in value) || value['net_sales'] === undefined) return false;
    return true;
}

export function BranchWithOrderCountAndNetSalesFromJSON(json: any): BranchWithOrderCountAndNetSales {
    return BranchWithOrderCountAndNetSalesFromJSONTyped(json, false);
}

export function BranchWithOrderCountAndNetSalesFromJSONTyped(json: any, ignoreDiscriminator: boolean): BranchWithOrderCountAndNetSales {
    if (json == null) {
        return json;
    }
    return {
        
        'branch': BranchFromJSON(json['branch']),
        'order_count': json['order_count'],
        'net_sales': json['net_sales'],
    };
}

export function BranchWithOrderCountAndNetSalesToJSON(json: any): BranchWithOrderCountAndNetSales {
    return BranchWithOrderCountAndNetSalesToJSONTyped(json, false);
}

export function BranchWithOrderCountAndNetSalesToJSONTyped(value?: BranchWithOrderCountAndNetSales | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'branch': BranchToJSON(value['branch']),
        'order_count': value['order_count'],
        'net_sales': value['net_sales'],
    };
}

