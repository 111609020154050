import React from "react";
import { DialogButtonsFooter } from "./DialogButtonsFooter";

export type DialogProps = {
  show: boolean;
  title: string;
  message: React.ReactNode;
  onConfirm: () => void;
  onCancel?: () => void;
  confirmText?: string;
  cancelText?: string;
  className?: string;
  titleClassName?: string;
  messageClassName?: string;
  isConfirmDisabled?: boolean;
  showConfirm?: boolean;
  showCancel?: boolean;
};

export function Dialog({
  show,
  title,
  message,
  onConfirm,
  onCancel,
  confirmText = "Confirm",
  cancelText = "Cancel",
  className,
  titleClassName = "",
  messageClassName = "",
  isConfirmDisabled = false,
  showConfirm = true,
  showCancel = true,
}: DialogProps) {
  const dialogElement = React.useRef<HTMLDialogElement>(null);

  React.useEffect(() => {
    const dialog = dialogElement.current;
    if (!dialog || !onCancel) return;

    dialog.addEventListener("close", onCancel);
    return () => {
      dialog.removeEventListener("close", onCancel);
    };
  }, [onCancel]);

  React.useEffect(() => {
    const dialog = dialogElement.current;
    if (!dialog) return;

    if (show) {
      dialog.showModal();
    } else {
      dialog.close();
    }
  }, [show]);

  return (
    <dialog ref={dialogElement} className={`overflow-visible m-auto`}>
      <div
        className={`p-0 shadow-none flex flex-col items-center w-[660px] justify-between min-h-[212px] ${className}`}
      >
        <div className="w-full flex justify-start bg-[#F6F8FA] h-14">
          <h2 className={`content-label py-4 px-8 ${titleClassName}`}>{title}</h2>
        </div>
        <div
          className={`w-full bg-white flex flex-col gap-2 justify-center items-center text-feintGray text-base ${messageClassName}`}
        >
          {message}
        </div>
        <DialogButtonsFooter
          confirmText={confirmText}
          onConfirm={() => {
            onConfirm();
            dialogElement.current?.close();
          }}
          isConfirmDisabled={isConfirmDisabled}
          showConfirm={showConfirm}
          cancelText={cancelText}
          onCancel={() => {
            dialogElement.current?.close();
          }}
          showCancel={showCancel}
          containerClassName="py-4"
        />
      </div>
    </dialog>
  );
}
