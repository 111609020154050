import { useMutation } from "@tanstack/react-query";
import { useAPI } from "@/api";
import { PostBudgetRequest, BudgetValidationErrorResponse } from "@norma-bi/bi-api";

export function useUploadBudget() {
  const api = useAPI();

  return useMutation<void, BudgetValidationErrorResponse, PostBudgetRequest>({
    mutationFn: async (args: PostBudgetRequest) => {
      await api.postBudget(args);
    },
    onError: (error: BudgetValidationErrorResponse) => {
      console.error("Upload error:", error);
    },
  });
}
