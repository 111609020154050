import { create } from "zustand";

export type ThoughtSpotTokenStore = {
  token: string | null;
  setToken: (token: string) => void;
};

export const useThoughtSpotToken = create<ThoughtSpotTokenStore>((set) => ({
  token: null,
  setToken(token) {
    set({
      token,
    });
  },
}));
