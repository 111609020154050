/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Sample } from './Sample';
import {
    SampleFromJSON,
    SampleFromJSONTyped,
    SampleToJSON,
    SampleToJSONTyped,
} from './Sample';

/**
 * 
 * @export
 * @interface MenuMixBusinessProductsQuantityTimeseriesResponse
 */
export interface MenuMixBusinessProductsQuantityTimeseriesResponse {
    /**
     * 
     * @type {Array<Sample>}
     * @memberof MenuMixBusinessProductsQuantityTimeseriesResponse
     */
    data: Array<Sample>;
}

/**
 * Check if a given object implements the MenuMixBusinessProductsQuantityTimeseriesResponse interface.
 */
export function instanceOfMenuMixBusinessProductsQuantityTimeseriesResponse(value: object): value is MenuMixBusinessProductsQuantityTimeseriesResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function MenuMixBusinessProductsQuantityTimeseriesResponseFromJSON(json: any): MenuMixBusinessProductsQuantityTimeseriesResponse {
    return MenuMixBusinessProductsQuantityTimeseriesResponseFromJSONTyped(json, false);
}

export function MenuMixBusinessProductsQuantityTimeseriesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MenuMixBusinessProductsQuantityTimeseriesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(SampleFromJSON)),
    };
}

export function MenuMixBusinessProductsQuantityTimeseriesResponseToJSON(json: any): MenuMixBusinessProductsQuantityTimeseriesResponse {
    return MenuMixBusinessProductsQuantityTimeseriesResponseToJSONTyped(json, false);
}

export function MenuMixBusinessProductsQuantityTimeseriesResponseToJSONTyped(value?: MenuMixBusinessProductsQuantityTimeseriesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(SampleToJSON)),
    };
}

