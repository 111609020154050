import { useQuery } from "@tanstack/react-query";
import { useAPI } from "@/api";
import { BudgetResponse, GetBudgetResolutionEnum } from "@norma-bi/bi-api";

export function useGetBudget(
  businessId: string | undefined,
  resolution: GetBudgetResolutionEnum,
  year: number,
) {
  const api = useAPI();

  return useQuery<BudgetResponse, Error>({
    queryKey: ["budget", businessId, resolution, year],
    queryFn: async () => {
      return await api.getBudget({
        business_id: businessId!,
        resolution,
        year,
      });
    },
    enabled: !!businessId && api.isAuthenticated,
  });
}
