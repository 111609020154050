import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isAllBusiness, useAPI } from "@/api";
import { customToast } from "@/utils/customToast";

export function useOnboardFoodicsAccounts() {
  const queryClient = useQueryClient();
  const api = useAPI();

  return useMutation({
    mutationFn: (args: { businessId: string; accountIds: string[] }) => {
      if (isAllBusiness(args.businessId)) {
        throw new Error("cannot onboard users to business in ALL BUSINESSES context");
      }
      return api.postOnboardUsersToBusiness({
        business_id: args.businessId,
        OnboardFoodicsUsersRequest: {
          ext_ids: args.accountIds,
        },
      });
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: ["users"],
        refetchType: "all",
      });
      await queryClient.invalidateQueries({
        queryKey: ["accounts"],
        refetchType: "all",
      });
      customToast(
        "Invitation was sent, please tell the user to check their email.",
        "success",
        "bottom",
      );
      return data;
    },
  });
}
