/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SupportTicket
 */
export interface SupportTicket {
    /**
     * 
     * @type {string}
     * @memberof SupportTicket
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SupportTicket
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof SupportTicket
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof SupportTicket
     */
    status: string;
    /**
     * 
     * @type {Date}
     * @memberof SupportTicket
     */
    created_at: Date;
}

/**
 * Check if a given object implements the SupportTicket interface.
 */
export function instanceOfSupportTicket(value: object): value is SupportTicket {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('created_at' in value) || value['created_at'] === undefined) return false;
    return true;
}

export function SupportTicketFromJSON(json: any): SupportTicket {
    return SupportTicketFromJSONTyped(json, false);
}

export function SupportTicketFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupportTicket {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'subject': json['subject'] == null ? undefined : json['subject'],
        'description': json['description'],
        'status': json['status'],
        'created_at': (new Date(json['created_at'])),
    };
}

export function SupportTicketToJSON(json: any): SupportTicket {
    return SupportTicketToJSONTyped(json, false);
}

export function SupportTicketToJSONTyped(value?: SupportTicket | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'subject': value['subject'],
        'description': value['description'],
        'status': value['status'],
        'created_at': ((value['created_at']).toISOString()),
    };
}

