import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAPI } from "@/api";

export function useDeactivateUser() {
  const queryClient = useQueryClient();
  const api = useAPI();

  return useMutation({
    mutationFn: (args: { userId: string }) => {
      return api.deleteUsersUserId({ user_id: args.userId });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });
}
