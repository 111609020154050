import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAPI } from "@/api";

export function useResendInvitation() {
  const queryClient = useQueryClient();
  const api = useAPI();

  return useMutation({
    mutationFn: (userId: string) =>
      api.postInviteFoodicsAccounts({ InviteFoodicsUsersRequest: { ids: [userId] } }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });
}
